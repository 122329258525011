.welcome-container {
  background: linear-gradient(
    180deg,
    #683fdb 99.99%,
    rgba(104, 63, 219, 0) 100%
  );
  width: 49.16vw;
  min-height: 100vh;
  height: fit-content;
  background-color: #683fdb;
  /* border: 2px solid black; */
}

.welcome-text {
  /* margin-top: 170px; */
  /* margin-top: 88px; */
  margin-top: 10.71vh;
  margin-left: 9.37vw;
  width: 30.42vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 3.33vw;
  line-height: 5, 48vh;
  text-align: center;
  color: #ffffff;
}

/* ------------login page carousel--csss---- */

.login-carousel .slick-list{
  margin-left:5.5vw !important;
}
.welcome-para {
  /* margin-top: 32px; */
  margin-top: 1vh;
  /* margin-left: 10.62vw; */
  width: 38.91vw !important;
  height: 4vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 1.38vw;
  line-height: 3.65vh;
  align-items: center;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  /* border: 2px solid #000000; */
}
.welcome-img {
  /* position: absolute;
  width: calc(65% - 330px );
  height: calc(100% -  310px);
  left: calc(12% - 118px);
  /* border: 2px solid red; */
  /* right:   calc(100% - 300px);
  max-width: 40%;
  height: -webkit-fill-available;  */
  /* margin: 0 auto; */
  margin-left: 4vw;
  width: 80%;

}
.image{
  width: 100%;
  height: 100%;
  /* border: 2px solid  black; */
}
/* .container {
  width: 65vw;
  height: 65vh;


  margin-left: 60px;
} */
.login-container{
  display: flex;
  flex-direction: column;
  margin-left: 5vw;
  height: 100%;
}
.logo-img {
  width: 189px;
  height: 44px;
  /* margin-top: 292px; */
  /* margin-top: 200px; */
  margin-top: 24.36vh;
  /* margin-left: 72px; */
}
.login-text {
  margin-top: 8.76vh;
  /* margin-left: 72px; */
  width: 82px;
  height: 28px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #333333;
  opacity: 0.8;
  white-space: nowrap;
}
/* .login-input-container{
  margin-left: 5vw;

} */

.login-input-container {
  margin-top: 3.89vh;
  /* margin-left: 72px; */
  min-width: 342px;
}
/* .input-pre{
  padding-bottom: 4px;
  /* width: 23.75vw; */
/* min-width: 300px; */
/* min-width: 250px; */

/* border-bottom: 1px solid #d0d0d0; */
/* } */
.phone-input {
  padding-bottom: 4px;
  width: 23.75vw;
  min-width: 300px;
  /* min-width: 250px; */
  /* min-width: 300px;
  width: 20.75vw;
border-style:none ; */
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  outline: none;
  appearance: none;
  border-bottom: 1px solid #d0d0d0;
}
.login-input-container input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  /* padding: 67px; */
  line-height: normal;
  color: #000000;
  opacity: 0.2;
  height: 5vw;
}
/* .login-input-container input[type="number"] {
  font-size: 1.11vw;
}
.login-input-container input[type="password"] {
  font-size: 1.11vw;
  padding-bottom: 0.55vw;
} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.password-input {
  padding-bottom: 4px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom: 1px solid #d0d0d0;
  /* opacity: 0.5; */
  outline: none;
  width: 23.75vw;
  min-width: 300px;
  /* min-width: 250px; */
  margin-top: 7%;
}
.eye-icn-clss{
  position: relative;
  left: -1.69vw;
  bottom: -0.41vw;
}
.eye-icn-clss > svg{
  width: 1.52vw;
  height: 1.52vw;
  cursor: pointer;
}
.login-error-msg{
  position: absolute;
  margin-top: 2vh;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;    
  color: #ED4A4A;
}
.or-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  opacity: 0.8;
  margin-top: 0.55vw;
}
/* .loginError {
  position: relative;
  color: red;
} */
.reqst-otp-btn {
  white-space: nowrap;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #2775eb;
  cursor: pointer;
  border-style: none;
  background-color: #ffffff;
}
.deactivate-reqst-otp-btn {
  white-space: nowrap;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #D6D6D6;
  cursor: pointer;
  border-style: none;
  background-color: #ffffff;
}
.login-btn {
  min-width: 308px;
  margin-top: 32px;
  /* margin-left: 5vw; */
  /* margin-left: -2vw; */
  min-height: 48px;
  width: 23.75vw;
  background: #d6d6d6;
  border-radius: 8px;
  border: navajowhite;
  /* cursor: pointer; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.active-login-btn {
  min-width: 308px;
  /* min-width: 258px; */
  margin-top: 32px;
  /* margin-left: 5vw; */
  /* margin-left: -2vw; */
  height: 48px;
  width: 23.75vw;
  background: #d6d6d6;
  border-radius: 8px;
  border: navajowhite;
  cursor: pointer;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  background: #ff9900;
}
button:focus {
  /* opacity: 0.5; */
}
.Forget-text {
  margin-top: 22px;
  /* margin-left: 5.9vw; */
  width: 117px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #2775eb;
  cursor: pointer;
}
.new-on-text {
  /* width: 105px; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  color: #333333;
  opacity: 0.8;
}
.Sign-up-btn {
  cursor: pointer;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  color: #2775eb;
}

@media only screen and (min-width: 1441px) {
  .welcome-container {
    /* width: 49.16vw;
    height: 100vh; */
  }

 
  .welcome-text {
    /* margin-top: 6.11vw; */
    margin-left: 9.37vw;
    width: 30.42vw;
    font-size: 3.33vw;
    line-height: 48px;
  }
  /* .welcome-para {
    /* margin-top: 2.22vw; */
    /* margin-left: 10.62vw;
    width: 27.91vw;
    font-size: 1.38vw;
    line-height: 2.08vw; */
  /* }  */
  .welcome-img {
    /* position: absolute;
    width: calc(65% - 330px );
    height: calc(100% -  310px);
    left: calc(12% - 118px);
    /* border: 2px solid red; */
    /* right:   calc(100% - 300px);
    max-width: 40%;
    height: -webkit-fill-available;  */
  
  }
  .image{
    width: 100%;
    height: 100%;
    /* border: 2px solid  black; */
  }

  .logo-img {
    width: 13.12vw;
    height: 3.05vw;
    /* margin-top: 20.88vh; */
    /* margin-left: 5vw */
  }
  .login-text {
    margin-top: 5vw;
    /* margin-left: 5vw; */
    width: 5.69vw;
    height: 1.95vw;
    font-size: 1.95vw;
    line-height: 1.95vw;
  }
  .login-input-container {
    margin-top: 2.22vw;
    /* margin-left: 5vw; */
  }
  /* .login-input-container input[type="number"] {
    font-size: 1.11vw;
  }
  .login-input-container input[type="password"] {
    font-size: 1.11vw;
    padding-bottom: 0.55vw;
  } */
  .phone-input {
    padding-bottom: 0.27vw;
    width: 23.75vw;
    height: 2vw;
  }
  .phone-input::placeholder {
    /* font-size: .9vw; */
    /* height: fit-content; */
    /* line-height: 1.25vw; */
  }

  .password-input {
    padding-bottom: 0.27vw;
    width: 23.75vw;
    margin-top: 7%;
    height: 2vw;
  }
  .login-error-msg{
   margin-top: 3vh;
    font-size: 0.83vw;
    line-height: 14px;    
    
  }
  .or-text {
    font-size: 0.97vw;
    line-height: 1.25vw;
  }
  .reqst-otp-btn {
    font-size: 0.97vw;
    line-height: 1.04vw;
  }
  .deactivate-reqst-otp-btn{
    font-size: 0.97vw;
    line-height: 1.04vw;
  }
  .login-btn {
    margin-top: 2.22vw;
    /* margin-left: 5vw; */
    height: 3.33vw;
    width: 23.75vw;
    border-radius: 0.55vw;
    font-size: 1.11vw;
    line-height: 1.38vw;
  }
  .active-login-btn {
    margin-top: 2.22vw;
    /* margin-left: 5vw; */
    height: 3.33vw;
    width: 23.75vw;

    border-radius: 0.55vw;

    font-size: 1.11vw;
    line-height: 1.38vw;
  }
  .Forget-text {
    margin-top: 1.52vw;
    /* margin-left: 5.9vw; */
    width: 8.12vw;
    font-size: 0.97vw;
    line-height: 1.04vw;
  }
  .new-on-text {
    font-size: 0.97vw;
    line-height: 1.25vw;
  }
  .Sign-up-btn {
    font-size: 0.97vw;
    line-height: 1.04vw;
  }

}
@media only screen and (max-width: 767px ) {
  .welcome-container {
    display: none;
  }
.login-container{
  margin-left: 9vw;
  overflow: hidden;
  /* height: 100vh; */
}  
.login-input-container input::placeholder {
  font-size: 4.22vw;
  height: 5vw;
  /* line-height: normal; */
  
}
.or-text{
  margin-top:1vw;
}
.eye-icn-clss{
left: -6.69vw;
bottom: -2vw;
}
.eye-icn-clss > svg{
  width:6vw;
  height: 6vw;

}
}