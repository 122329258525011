/* empty--screen -----css---------- */
.client-table-empty-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 33vw; */
}
.client-table-loder-screen{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    height: 33vw;
}
.client-table-empty-screen > svg {
  margin-top: 2.5vh;
  width: 27vw;
  height: 27vw;
}
.client-table-empty-screen > h1 {
  margin-bottom: 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1.52vw;
  line-height: 1.66vw;
  color: #333333;
  opacity: 0.8;
  /* margin-top: 1.50vh; */
}
.client-table-empty-screen > p {
  margin-top: 0.97vh;
  margin-bottom: 3vw;
  /* width: 23.25vw; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.25vw;
  color: #999999;
}
/* --------- */
.selecttag-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.97vw;
  line-height: 1.18vw;
  color: #000000;
  white-space: nowrap;
  width: 11.3vw;
  /* opacity: 0.8; */
}
.click-div {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  /* opacity: 0.5; */
  border-bottom: 1px solid #d0d0d0;
}
.click-div > div {
  /* background-color: #2775eb; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.up-arrow:after {
  display: inline-block;
  content: " ";
  margin-left: 0.27vw;
  margin-bottom: 0.27vw;
  border-left: 0.347vw solid transparent;
  border-right: 0.347vw solid transparent;
  border-bottom: 0.347vw solid black;
}

.down-arrow:after {
  display: inline-block;
  content: " ";
  margin-left: 0.27vw;
  margin-bottom: 0.27vw;
  border-left: 0.347vw solid transparent;
  border-right: 0.347vw solid transparent;
  border-top: 0.347vw solid black;
}
.form {
  /* position: absolute; */
  /* z-index: -1000; */
}
.expand-cont {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 13.71vw;
  /* height: 229px; */
  background: #ffffff;
  /* background-position: center; */

  /* z-index: 1000; */
  border: 1px solid #d6d6d6;
  box-shadow: 5px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.27vw;

  /* border: 2px solid #1e90ff; */
  /* background: none; */
  opacity: 1;
}
.expand-cont > h6 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.83vw;
  line-height: 0.97vw;
  color: #000000;
  opacity: 0.8;
  margin-left: 1.1vw;
  margin-top: 1.1vw;
  margin-bottom: 1.1vw;
}
.profess-heading {
  margin-top: 0vw;
  margin-left: 1.11vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 0.97vw;
  color: #000000;
  opacity: 0.8;
}
.investr-type-Heading {
  margin-top: 2vw;
  margin-bottom: 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 0.97vw;
  color: #000000;
  opacity: 0.8;
  margin-left: -6.8vw;
}

.block {
  display: flex;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 0.97vw;
  line-height: 1.18vw;
  color: #000000;
  opacity: 0.8;
  /* margin-bottom: 7px; */
}
.block > input {
  margin-right: 0.97vw;
  width: 1.11vw;
  height: 1.11vw;
}
/* .table-style-one  >table{
  border-radius: 1.11vw;
} */
/* table{
  border-radius: 1.11vw;
} */
.table-style-one tr:nth-child(even) {
  background: rgba(217, 217, 217, 0.3);
}
.table-style-one tr:nth-child(odd) {
  background: rgba(217, 217, 217, 0.1);
}
.table-style-one table {
  border-collapse: collapse;
}

.table-style-one tr {
  /* width: 100%; */
  height: 3.68vw;
  text-align: center;
  align-items: center;
}
.table-style-one tr:hover td{
  background: #F4EEFF;
}
.table-style-one th {
  /* background: #d9d9d9; */
  width: 73.33vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.83vw;
  line-height: 1.04vw;
  color: #000000;
  opacity: 1;
  padding-left: 2.01vw;
  text-align: initial;
  white-space: nowrap;
}
.table-style-one td {
  width: 73.33vw;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.97vw;
  line-height: 1.18vw;
  padding-left: 2.01vw;
  text-align: initial;
  color: #000000;
  opacity: 1;
}
/* --------------modal--------csss----------- */
.client-table-modal {
  width: 47.7vw !important;
  background: #ffffff;
  border-radius: 1.11vw;
}
.client-table-modal .ant-modal-header {
  border-radius: 1.11vw;
  padding: 50px 0 0 64px;

  border-bottom: none;
}
.client-table-modal .ant-modal-header .ant-modal-title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  white-space: norap;
}
.client-table-modal .ant-modal-content {
  border-radius: 1.11vw;
  box-shadow: none;
}
.client-table-modal .ant-modal-body {
  padding: 0;
  margin-left: 64px;
  margin-top: 2.22vw;
}
.text-area-cont {
  margin-top: 2.22vw;
}
.text-area-cont > h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.97vw;
  line-height: 1.18vw;
  color: #000000;
  opacity: 0.8;
}

.text-area-cont textarea {
  width: 38.95vw;
  height: 16.11vw;
  font-size: 1.11vw;
  padding-top: 15px;
  padding-left: 20px;
  outline: none;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 0.55vw;
}

.text-area-cont textarea::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 1.11vw;
  line-height: 36px;
  color: #666666;
  opacity: 0.5;
}
textarea::-webkit-input-placeholder {
  /* padding: 100px; */
}
.client-table-modal .ant-modal-footer {
  text-align: left;
  margin-left: 45px !important;
  margin-top: 1.22vw;
  border-top: none;

  /* gap: 57px; */
  /* border-style: none; */
}
.client-table-modal .ant-modal-footer .save-btn {
  border-style: none;
  /* width: 171px;
  height: 48px; */
  width: 11.875vw;
  height: 3.33vw;
  background: #ff9900;
  border-radius: 8px;
  /* color: #2775EB; */
  color: #ffffff !important;
  margin-bottom: 10px;
}
.client-table-modal .ant-modal-footer .save-btn span {
  color: #ffffff;
}
.client-table-modal .ant-modal-footer .cancel-btn {
  box-shadow: unset;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 15px;
  border-style: none;
  background: none;
}
.client-table-modal .ant-modal-footer .cancel-btn span {
  color: #2775eb;
}
.select-time-cont {
}

.main-select-time-cont {
  margin-bottom: 2.22vw;
  display: flex;
  gap: 1.39vw;
}
.select-time-cont .selecter {
  width: 10.9vw;
  min-width: 0;
}
.select-time-cont .ant-select {
  width: 10.903vw;
  border-bottom: 1px solid #d0d0d0 !important;
}
.select-time-cont .ant-select-selector {
  /* width: 95% !important; */
  border: none !important;
  opacity: 0.5;
  padding: 0 !important;
}
.select-time-cont .up-arrow:after {
  /* display: inline-block; */
  position: absolute;
  top: 40%;
  right: 0;
  content: " ";
  margin-left: 0.27vw;
  margin-bottom: 0.27vw;
  border-left: 0.347vw solid transparent;
  border-right: 0.347vw solid transparent;
  border-bottom: 0.347vw solid black;
}
.ant-select-arrow {
  display: none;
}
.select-time-cont .down-arrow:after {
  position: absolute;
  top: 40%;
  right: 0;
  content: " ";
  margin-left: 0.27vw;
  margin-bottom: 0.27vw;
  border-left: 0.347vw solid transparent;
  border-right: 0.347vw solid transparent;
  border-top: 0.347vw solid black;
}
.schedule-table-modal .ant-select-item-option-content {
  font-size: 1.2vw;
}
.schedule-table-modal .ant-select-item,
.ant-select-item-empty {
  min-height: 2.22vw !important;
  padding: 0.347vw 0.83vw;
  line-height: 1.527vw;
}
.schedule-table-modal {
  overflow: scroll !important;
}
.ant-select-item-option-active {
  background-color: white !important;
}
.schedule-table-modal .ant-select-selection-item {
  padding: 1vw;
}

.comunication-icon-container {
  display: flex;
  gap: 1.8vw;
  align-items: center;
  cursor: pointer;
  padding-right: 1vw;
}
.expand-cont-in-mob {
  display: none;
}
.ant-modal-root {
  /* display: none; */
}

@media only screen and (min-width: 1441px) {
  .schedule-table-modal .ant-modal-header {
    padding: 3.47vw 0 0 4.44vw;
  }
  .schedule-table-modal .ant-modal-body {
    margin-left: 4.44vw;
  }
  .schedule-table-modal .ant-modal-footer {
    margin-left: 3.125vw !important;
  }
  .schedule-table-modal .ant-modal-footer {
    padding: 0.694vw 1.11vw;
  }
  .schedule-table-modal
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 2.22vw !important;
  }
  .schedule-table-modal .select-time-cont .down-arrow:after {
    top: 25%;
  }
  .schedule-table-modal .ant-modal-close {
    top: 1vw;
    right: 1vw;
  }
  .schedule-table-modal {
    top: 6.944vw;
    padding: 0 0 1.66vw;
  }
}

@media only screen and (max-width: 767px) {
  .table-style-one tr {
    height: 53px;
  }
  .table-style-one th {
    font-size: 12px;
    line-height: 14px;
    padding-left: 20px;
  }
  .table-style-one td {
    /* font-weight: 400; */
    font-size: 14px;
    line-height: 17px;
    padding-left: 20px;
    /* opacity: 0.8; */
  }
  .comunication-icon-container {
    /* gap: 15px;  */
  }
  .comunication-icon-container > svg {
    width: 29px;
    height: 24px;
    padding-right: 8px;
  }
  .selecttag-text {
    font-size: 12px;
    line-height: 14px;
    width: unset;
  }
  .expand-cont {
    display: none;
  }
  .up-arrow:after {
    display: inline-block;
    content: " ";
    margin-left: 2.27vw;
    margin-bottom: 0.27vw;
    border-left: 1.347vw solid transparent;
    border-right: 1.347vw solid transparent;
    border-bottom: 1.347vw solid black;
  }
  .down-arrow:after {
    display: inline-block;
    content: " ";
    margin-left: 2.27vw;
    margin-bottom: 0.27vw;
    border-left: 1.347vw solid transparent;
    border-right: 1.347vw solid transparent;
    border-top: 1.347vw solid black;
  }
  /* -----------------tags-modals for mobile-screen------------------------------------------------ */
  .expand-cont-in-mob {
    display: flex;
  }
  .expand-cont-in-mob .selecttag-text {
    font-size: 14px;
    line-height: 18px;
  }
  .expand-cont-in-mob .ant-modal-footer {
    display: none;
  }
  .expand-cont-in-mob .ant-modal-body {
    display: flex;
    justify-content: center;
    /* border-radius: 16px; */
  }
  .expand-cont-in-mob .ant-modal-content {
    width: 70vw;
    height: auto;
    left: 13vw;
    top: 20vw;
    border-radius: 16px;
  }
  .profess-heading {
    font-size: 12px;
    line-height: 14px;
  }
  .investr-type-Heading {
    font-size: 12px;
    line-height: 14px;
    /* margin-top:7vw; */
    margin-left: unset;
    margin-bottom: 2vw;
    opacity: unset;
  }
  .block {
    display: block;
    font-size: 14px;
    line-height: 17px;
  }
  .block > input {
    width: 14px;
    height: 14px;
    margin-right: 1.97vw;
  }
  .expand-cont-in-mob .anticon {
    /* width: 16px;
          height: 16px; */
    font-size: 16px;
    line-height: 18px;
  }

  /* --------------Communication------modal-------css-------for ------moble-------------------------------- */
  .client-table-modal {
    width: 87.94vw !important;
    /* height: 138.97vw; */
    border-radius: 16px;
    overflow: hidden !important;
  }
  .client-table-modal .anticon {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
  }
  .client-table-modal .ant-modal-header {
    padding-top: 32px;
    padding-left: 20px;
    border-radius: unset;
  }
  .client-table-modal .ant-modal-header .ant-modal-title {
    font-size: 18px;
    line-height: 18px;
  }
  /* .client-table-modal .ant-modal-content{
        width: 87.94vw;
        height: 138.97vw;
      } */
  .client-table-modal .ant-modal-body {
    padding: 20px;
    margin: unset;
  }
  .client-table-modal .ant-modal-footer {
    margin-left: unset !important;
    display: flex;
    align-items: baseline;
    gap: 12.30vw;
    /* margin-top: -4vw;¯ß */
  }
  .client-table-modal .ant-modal-footer .save-btn {
    width: 43.84vw;
    height: 12.3vw;

  }
  .client-table-modal .ant-modal-footer .save-btn span{
    font-size: 16px;
    line-height: 20px;
  }
  .client-table-modal .ant-modal-footer .cancel-btn span{
    font-size: 16px;
    line-height: 15px;
  }
  .client-table-modal .date-input-cont .add-event-input {
    width: 77.69vw;
    border-radius: unset;
  }
  .client-table-modal .date-input-cont .add-event-input input {
    font-size: 14px;
    line-height: 18px;
  }
  .client-table-modal .ant-picker-input > input::placeholder {
    font-size: 14px;
    line-height: 18px;
  }
  .main-select-time-cont {
    gap: 4.86vw;
  }
  .select-time-cont .ant-select {
    width: 36.39vw;
  }
  .select-time-cont .down-arrow:after{
  
    border-left: 1.347vw solid transparent;
    border-right: 1.347vw solid transparent;
    border-top: 1.347vw solid black;
  }
  .select-time-cont .up-arrow:after {
 
    
    border-left: 1.347vw solid transparent;
    border-right: 1.347vw solid transparent;
    border-bottom: 1.347vw solid black;
  }
  .schedule-table-modal .ant-select-selection-item {
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }
  .text-area-cont > h5 {
    margin-top: 6.15vw;
    font-size: 12px;
    line-height: 14px;
  }
  .text-area-cont textarea {
    width: 77.69vw;
    height: 59.48vw;
    font-size: 14px;
    line-height: 36px;
    border-radius: 8px;
  }
  .text-area-cont textarea::placeholder {
    font-size: 14px;
    line-height: 36px;
  }
}
