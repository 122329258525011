.earning-main-container{
  padding:2.22vw 0 0 3.33vw;
  /* margin-left: 3.33vw;
  margin-top: 2.22vw; */
}


.payment-detail-container {
  display: flex;
  /* width: 347px;
  flex-wrap: wrap;
  justify-content: space-between; */
  /* gap: 25px; */
  width: 59.63vw;
  /* gap: 25px; */
  /* gap: 268px; */
  /* justify-content: flex-start; */
  justify-content: space-between;
}
.payment-detail-container > div{
   margin-top: 2.22vw;
}
.upComing-transaction-text {
  margin-top: 73px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
}
.upComing-transaction-container {
  display: flex;
  /* width: 347px;
  flex-wrap: wrap; */
  justify-content: space-between;
  width: 60.63vw;
  /* gap: 25px; */
  margin-bottom: 40px;
}
@media only screen and (min-width: 1441px) {
  /* .payment-detail-container {
    width: 24.09vw;
    gap: 1.73vw;
  } */
  .upComing-transaction-text {
    margin-top: 73px;
    font-size: 1.66vw;
    line-height: 1.94vw;
  }
  /* .upComing-transaction-container {
    width: 24.09vw;

    gap: 1.73vw;
    margin-bottom: 2.77vw;
  } */
}
@media only screen and (max-width: 767px) {
  .earning-main-container{
    padding: 8.20vw 0 0 6.15vw;
  }
  .payment-detail-container{
    flex-wrap: wrap;
    width: 82vw;
    gap: 6.15vw;
    row-gap: 4.1vw;
  }
}