.heading-personal {
  margin-top: 72px;
  /* margin-left: 5vw; */
  width: 296px;
  height: 28px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  color: #333333;
  opacity: 0.8;
  margin-bottom: 0;
}
.name-input {
  padding-bottom: 0.27vw;

  margin-top: 2.22vw;
  width: 23.75vw;
  min-width: 300px;
  /* min-width: 250px; */
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  outline: none;
  appearance: none;
  border-bottom: 1px solid #d0d0d0;
  /* opacity: 0.5; */
  /* color: #f12727; */
}
.alert-msg-cont{

}
.alert-msg{
  position: absolute;
  color: red;
  margin: unset;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 1vw;
  line-height:1.4vw;
}
input[type="text"] {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 8px;
  color: #000000;
  /* opacity: 0.8; */
}
/* .name-input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 18px; */
/* color: #000000;
  opacity: 0.2; */
/* } */
.select-occupation {
  margin-top: 32px;
  padding-bottom: 4px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom: 1px solid #d0d0d0;
  outline: none;
  width: 23.75vw;
  min-width: 300px;
  /* min-width: 250px; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  opacity: 0.8;
}
.btn-continue {
  margin-top:32px;
  /* margin-left: 5vw; */
  min-width: 308px;
  /* min-width: 258px; */
  width: 23.75vw;
  height: 48px;
  background: #d6d6d6;
  border-radius: 8px;
  border: navajowhite;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}
.active-btn-continue {
  margin-top: 32px;
  /* margin-left: 5vw; */
  /* min-width: 258px; */
  min-width: 308px;

  width: 23.75vw;
  height: 48px;
  background: #d6d6d6;
  border-radius: 8px;
  border: navajowhite;
  background: #ff9900;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}
.do-later-text {
  margin-top: 20px;
  /* margin-left: 9.1vw; */
  margin-left: 137px;
  width: 77px;
  height: 15px;
  cursor: pointer;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #2775eb;
  margin-bottom: 35px;
}

.arn-checkbox-cont {
  margin-top: 2.22vw;
  display: flex;
}
.arn-checkbox-cont p{
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.25vw;
  color: #666666;
  margin-bottom: 0;
}
.arn-checkbox-cont input {
  width: 1.11vw;
  height: 1.11vw;
  margin-right: 0.76vw;
  background: #683fdb;
  border: 1.5px solid #6e42da;
  border-radius: 4px;
}
.arn-input-prefix{
  border-bottom: 1px solid #d0d0d0;
  width: 23.75vw;
  min-width: 300px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.25vw;
  color: #000000;
  /* opacity: 0.8; */
}
.arn-input{
  /* position: absolute; */
  margin-top: 1.66vw;
  padding-bottom: 0.27vw;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  /* border-bottom: 1px solid #d0d0d0; */
  border-bottom-style: none;
  outline: none;
  /* width: 20.75vw;
  min-width: 300px; */
  /* min-width: 250px; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.25vw;
  color: #000000;
  opacity: 0.8;
}
.hide-arn-input{
  display: none;
}
@media only screen and (min-width: 1441px) {
  .heading-personal {
    margin-top: 5vw;
    width: 27.55vw;
    height: 1.94vw;
    font-size: 1.94vw;
    line-height: 1.94vw;
  }
  /* .name-input {
    padding-bottom: 0.27vw;
    margin-top: 3.47vw;
    width: 23.75vw;
   
  } */
  input[type="text"] {
    font-size: 1.11vw;
    line-height: 1.25vw;
    /* padding-bottom: 8px; */
  }
  .name-input::placeholder {
    font-size: 1.11vw;
    line-height: 1.25vw;
  }
  .select-occupation {
    margin-top: 2.22vw;
    padding-bottom: 0.27vw;
    width: 23.75vw;
    min-width: 17.36vw;
    font-size: 1.11vw;
    line-height: 1.25vw;
  }
  .btn-continue {
    margin-top: 2.22vw;
    /* margin-left: 5vw; */
    width: 23.75vw;
    height: 3.33vw;
    border-radius: 0.55vw;
    font-size: 1.11vw;
    line-height: 1.38vw;
  }
  .active-btn-continue {
    margin-top: 2.22vw;
    /* margin-left: 5vw; */
    width: 23.75vw;
    height: 3.33vw;

    border-radius: 0.55vw;
    font-size: 1.11vw;
    line-height: 1.38vw;
  }
  .do-later-text {
    margin-top: 1.22vw;
    margin-left: 9.37vw;
    width: 5.34vw;
    height: 1.04vw;
    font-size: 1.11vw;
    line-height: 1.04vw;
    margin-bottom: 2.43vw;
  }
}
@media only screen and (max-width: 767px){
  .name-input{
    margin-top: 8.22vw;
  }
  .arn-checkbox-cont input{
    width: 4.11vw;
    height: 4.11vw;
 
  }
  .arn-checkbox-cont p{
    font-size: 3.9vw;
    line-height:4.3vw;
  }
  .arn-input-prefix{
    font-size: 4.22vw;
    margin-top: 4vw;
  }
}