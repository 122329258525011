.checkbox-filter-cont {}

.checkbox-filter-cont>.click-div {
  display: flex;
  width: 17.08vw;
  cursor: pointer;
  /* margin-bottom: 1.42vw; */
}

.checkbox-filter-cont>.expand-cont {
  width: 17.3vw;
  height: 18vw;
}

.search-cont {
  /* width: 1vw; */
  display: flex;
  align-items: flex-end;

  border-bottom: 0.069vw solid #d0d0d0;
}

.search-cont > svg{
  width: 1.66vw;
  height: 1.66vw;
  margin-bottom: 0.27vw;
}

.search-cont-hide {
  display: none;
}

.expand-cont-clients {
  height: 15vw !important;
}

.expand-cont-input {
  outline: none;
  border-style: none;
  margin-top: 1.31vw;
  margin-left: 1.11vw;
  /* width: 13vw; */
}

/* .expand-cont-input-hide{
   display: none;
}  */
.expand-cont-value {
  overflow-y: scroll;
  margin-right: 1vw;

  height: 10vw;
}

.expand-cont-value::-webkit-scrollbar {
  width: 3px;
  /* height: 1px; */

}

.expand-cont-value::-webkit-scrollbar-track-piece {
  background: #DADADA;
  opacity: 0.4;
  border-radius: 2.08vw;
}

::-webkit-scrollbar-thumb {
  /* height: 3px; */
  background: #683FDB;
  border-radius: 2.08vw;
}
.heading-clearAll-cont{
  display: flex;

  justify-content: space-between;
  align-items: baseline;
  width: 16vw;
}
.heading-clearAll-cont > h6{
  margin: unset;
  font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 0.83vw;
line-height: 1.04vw;
color: #2775EB;
cursor: pointer;
}
.checkbox-filter-cont .heading {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.83vw;
  line-height: 0.97vw;
  color: #000000;
  opacity: 0.8;
  margin-left: 1.11vw;
  margin-top: 1.38vw;
  margin-bottom: 8px;
  white-space: nowrap;
}

@media only screen and (max-width: 767px){
  .checkbox-filter-cont > .expand-cont{
    display: flex;
    width :87.69vw;
    height: 70.48vw !important;

  }
  .search-cont{
    justify-content: space-between;
    padding: 2vw 2vw 2vw 2vw;

  }
  .search-cont > svg{
    width: 24px;
    height: 24px;
  }
  .expand-cont-clients{
    /* height: 70.48vw !important; */
  }
  .heading-clearAll-cont{
    width: unset;
    margin: unset;
    padding: 4.10vw 4.10vw 0 4.10vw;
  }
  .heading-clearAll-cont > h6{
    font-size: 12px;
    line-height: 15px;
  }
  .checkbox-filter-cont .heading{
    font-size: 12px;
    line-height: 14px;
    /* margin-bottom: unset; */
  }
  .checkbox-filter-cont>.click-div{
    width: 87.69vw;
  }
  .expand-cont-value{
    display: flex;
    flex-direction: column;
    gap: 4.87vw;
    /* margin: unset; */
    padding: 4.10vw 4.10vw 4.10vw 4.10vw;
    height:55vw;
    margin-right:3vw;
  }
}