/* --------PersonalFormOverView-------- */

.overViewContainer{
    display: flex;
    flex-direction: column;
    gap: 2.22vw;
    margin-left: 25px;
    margin-top: 1.66vw;
}
.edit-btn{
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 15px;
color: #2775EB;
cursor: pointer;
}
.edit-next-btn-container{
    display: flex;
    gap: 70px;
}
@media only screen and (max-width: 767px){
    .overViewContainer{
        margin: unset;
        padding: 5.64vw 0 0 6.15vw;
        gap: 8.20vw;
    }
}