.latestNews-main-cont {
  /* margin-top: 54px;
  margin-left: 3.33vw; */
}
.heading-text {
  /* font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
  margin: 0;
  white-space: nowrap; */
}
.latestNews-main-cont p {
  /* font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  white-space: nowrap;
  margin-bottom: 0; */
}
.about-stocks-news-cont {
  /* margin-top: 2.22vw; */
}
.about-stocks-news-cont h1 {
  /* font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
  white-space: nowrap;
  margin-bottom: 0; */
}
.about-mutual-fund-news-cont {
  /* margin-top: 3.33vw; */
}
.fund-in-focus-news-cont {
  /* margin-top: 3.33vw; */
}

/* ---------Carousel----css-------------- */
.carousel-cont {
  /* margin-top: 1.11vw; */
}
.carousel-cont .slick-prev {
  /* background: red; */
}
.carousel-cont .slick-next {
  /* background: red;
  right: 55px !important; */
}
.carousel-cont .slick-list {
  /* width: 73.33vw; */
  /* border: 4px solid #333333; */
  /* height: 150px; */
}

.carousel-conetnt {
  width: 23.33vw !important;
  height: 16.56vh !important;
  background: #ffffff;
  /* box-shadow: 0px 2px 18px rgba(116, 82, 214, 0.15); */
  /* border-radius: 1.11vw; */
  /* border: 2px solide green; */
  /* object-fit: contain; */
}
.div-content {
  padding: 1.2vw;
}
.div-content h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.38vw;
  letter-spacing: 0.1px;
  color: #333333;
  opacity: 0.8;
  margin: 0;
}
.div-content h1:hover{
  text-decoration-line: underline;
}
.div-content h6 {
  margin-top: 0.55vw;
  width: 20.55vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 0.97vw;
  line-height: 1.38vw;
  color: #666666;
  margin-bottom: 0;
}
.div-content h5 {
    margin-top: 0.11vw;
    margin-bottom: 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.83vw;
  line-height: 1.18vw;
  color: #666666;
}
@media only screen  and (max-width: 767px){
  .div-content{
    padding: 5.12vw;
  }
  .div-content h1{
    font-size: 12px;
    line-height: 14px;
  }
  .div-content h5{
    font-size: 10px;
    line-height: 12px;
  }
}