
.agent-propfile-main-contanier{

}
.error-message-div{
  margin-top: -2.22vw;
}
.about-agent-container{
  display: flex;
  margin-left: 3.33vw;
  gap: 9.37vw;
  margin-top: 2.22vw;
}
.agent-pro-text {
  width: 143px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  margin-left: 48px;
  margin-top: 40px;
  /* Black shades/Black shade 02 */
  color: #333333;
  white-space: nowrap;
}
/* 
.circle{
  width: 10px;
  height: 10px;
  border-width: 1px solid blue;
  color: #000000;
} */
/* .MuiStepLabel-iconContainer {
   display: none !important;
} */
.stepper-main-container{
  margin-left: 3.33vw;
  margin-top: 2.78vw;
}
.MuiStepIcon-text{
  /* background-image: url("/assets/Logo.png") !important; */
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: #d6d6d6 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #683fdb !important ;
}
.css-4ff9q7.Mui-active{
  color: #683fdb !important ;
}
.css-4ff9q7{
  color: #d6d6d6 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #3ea400 !important;
  cursor: pointer;
}
.css-4ff9q7.Mui-completed{
  color: #3ea400 !important;
  cursor: pointer;
}
/* .MuiStepLabel-MuiStepLabel-completed.MuiStepLabel-active {
  font-family: "Rubik" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  text-decoration-line: underline !important;
  color: #333333 !important;
} */
.MuiStepLabel-label {
  font-family: "Rubik" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  margin-left: 24px !important;
  color: rgba(3, 3, 3, 0.2) !important;
}
.MuiStepLabel-label.MuiStepLabel-completed {
  font-family: "Rubik" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  margin-left: 24px !important;
  text-decoration-line: underline !important;
  color: #333333 !important;
  cursor: pointer;
}
.MuiStepLabel-label.MuiStepLabel-active {
  font-family: "Rubik" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  margin-left: 24px !important;
  /* identical to box height */

  /* Black shades/Black shade 02 */

  color: #333333 !important;
}
.css-1hv8oq8-MuiStepLabel-label.Mui-active{
  color: rgba(0, 0, 0, 0.87) !important;
  /* text-decoration: underline; */
  
}
.css-2fdkz6.Mui-active{
  color: rgba(0, 0, 0, 0.87) !important;
}
.css-1hv8oq8-MuiStepLabel-label.Mui-completed{
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: underline;
  cursor: pointer;
}
.css-2fdkz6.Mui-completed{
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: underline;
  cursor: pointer;
}
.MuiStepLabel-label {
  color: rgba(0, 0, 0, 0.54) !important;
  /* opacity: 0.2; */
}
.save-next-btn {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #2775eb;
  cursor: pointer;
  margin-left: 18px;
  margin-top: 48px;
}
.hide-save-next-btn {
  display: none;
}

/* -------------Personal----Form----css------------- */
.agent-personal-detail-main-container{
  display: flex;
  flex-direction: column;
  gap: 2.22vw;
  margin-top: 1.67vw;
  margin-left: 1.66vw;
}
.phone-input-prefix{
  width: 336px;

  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  opacity: 0.8;
  border-bottom: 1px solid #d0d0d0;
}
.input-phone{
  border-style: none;
  outline: none;
}

.email-phone-add-input {
  width: 336px;
  border-style: none;
  outline: none;
  border-bottom: 1px solid #d0d0d0;
  /* background-color: brown; */
}
.error-text{
  position: absolute ;
  margin-top: 30px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: red;

}
/* select{
    width: 336px;
} */
select {
  padding-bottom: 8px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom: 1px solid #d0d0d0;
  outline: none;
  width: 336px;
  min-width: 250px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  opacity: 0.8;
}

 /* ----------------BankForm---CSS------------- */
 .error-msg-text{
  position: absolute;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color:red;
  opacity: 0.6;
  /* margin-top: -2vw; */
  margin-bottom: 0;
  /* margin-left: -26vw; */
 }



/* -----------Agreement---Upload-----css-------------- */
.agrement-upload-main-container{
  padding-left: 1.66vw;
}
.agreement-para {
    margin-top: 32px;
  width: 344px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.8);
}
.agreement-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #2775eb;
  text-decoration: underline;
  cursor: pointer;
}
.doc-container {
  display: none;
}
.doc-container-visible {
  display: flex;
}
.submit-btn-cont{
  margin-left: 9.50vw;
  margin-top: 2vw;
  cursor: pointer;
}
.submit-btn-cont > .submit-btn{
  border-style: none;
  width: 8.8vw;
  height: 2.22vw;
  background: #ff9900;
  border-radius: 0.55vw;
  color: #ffffff ;
  margin-right: 1vw;
  cursor: pointer;

}
/* .submit-btn-cont >  {
  margin-left: 2vw;
} */
/* -------------Products-Plan----css-------------------- */
.checkbox-heading {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin-top: 32px;
    color: rgba(0, 0, 0, 0.8);
}
.checkbox-input,
input[type="checkbox"] {
  accent-color: #683fdb;
  border-radius: 4px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}


/* ---------------AgentReviewpage -----css----- */
.formleveltext{
font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
color: #333333;
}
.edit-text{
  font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 15px;
margin-left: 24px;
color: #2775EB;
}

@media only screen and (min-width: 1441px) {
  .agent-pro-text {
    width: 9.93vw;
    font-size: 1.66vw;
    line-height: 1.94vw;
    margin-left: 3.33vw;
    margin-top: 2.77vw;
  }





  .MuiStepLabel-label {
  
    font-size: 1.38vw !important;
    line-height: 1.66vw !important;
    margin-left: 1.66vw !important;
 
  }
  .MuiStepLabel-label.MuiStepLabel-completed {
   
    font-size: 1.38vw !important;
    line-height: 1.66vw !important;
    margin-left: 1.66vw !important;
   
  }
  .MuiStepLabel-label.MuiStepLabel-active {
   
    font-size: 1.38vw !important;
    line-height: 1.66vw !important;
    margin-left: 1.66vw !important;
   
  }

 

  .save-next-btn {
    font-size: 1.11vw;
    line-height: 1.09vw;
    margin-top: 3.33vw;
    margin-left: 1.25vw;
  }

  /* -------------Personal----Form----css------------- */
  .phone-input-prefix{
    width: 23.33vw;
   
    font-size: 1.11vw;
    line-height: 1.25vw;
 
  }
  .email-phone-add-input {
    width: 23.33vw;
  }
  .error-text{
   
    margin-top: 2.08vw;
    
    font-size: 1.11vw;
    line-height: 1.25vw;

  
  }
  /* select{
            width: 336px;
        } */
  select {
    padding-bottom: 0.55vw;

    width: 23.33vw;
    min-width: 250px;

    font-size: 1.11vw;
    line-height: 1.25vw;
  }



 /* ----------------BankForm---CSS------------- */
 .error-msg-text{
  /* margin-top: 5.4vw; */
  font-size: 0.97vw;
  line-height: 1.18vw;
 }





  /* -----------Agreement---Upload-----css-------------- */

  .agreement-para {
    width: 23.88vw;
   margin-top: 2.22vw;
    font-size: 1.38vw;
    line-height: 2.08vw;
  }
  .agreement-text {
    font-size: 1.38vw;
    line-height: 2.08vw;
  }

  /* -------------Products-Plan----css-------------------- */
  .checkbox-heading {
    margin-top: 2.22vw;
    font-size: 1.38vw;
    line-height: 2.08vw;

  }

  .checkbox-input,
  input[type="checkbox"] {
    accent-color: #683fdb;
    font-size: 1.11vw;
    line-height: 1.25vw;
  }
}
@media only screen and (max-width: 767px){
  .agent-propfile-main-contanier{
     padding: 8.71vw 5vw 0 6.15vw;
  }
  .error-message-div{
     margin-top: -8.40vw;
  }
  .about-agent-container{
    margin: 4.10vw 0 0 0;
    flex-wrap: wrap;
    row-gap: 6.15vw;
    justify-content: space-between;
  }
  .agent-pro-text{
    margin:unset;
    font-size: 18px;
    line-height: 18px;  
    opacity: 0.8;
    color: #000000;
  }
  .stepper-main-container{
    /* display: none; */
    margin: 8.20vw 0 0 0;
  }


  .agent-personal-detail-main-container{
   margin: unset;
    padding: 5.64vw 0 0  5.9vw;
    gap: 8.40vw;
  }
.email-phone-add-input{
  width: 75.38vw;
}
.phone-input-prefix{
  width: 75.38vw;
}
.agent-personal-detail-main-container > select{
  width: 75.38vw;
}
.error-msg-text{
  margin-top:1.1vw;
}
  .agrement-upload-main-container{
    padding-left: 4.66vw;
  }
  .agreement-para{
    width: unset;
  }
  .agrement-upload-main-container > svg{
    width: 75.38vw !important;
    height: 41.02vw !important;
  }
  .submit-btn-cont{
    display: flex;
    justify-content: center;
    margin-left: unset;
  }
  .submit-btn-cont > .submit-btn{
   width: 19.8vw;
   border-radius: 1.55vw;
   height: unset;

  }
  /* ----------overViewDetails-------------------------------- */
  /* .overViewContainer{
    margin: 0 0 0 6.15vw;
    gap: 8.20vw;
  } */
}
