.input-pre{

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  padding-bottom: 4px;
  width: 20.75vw;
  min-width: 300px;

  border-bottom: 1px solid #d0d0d0;

}
.number-props-input{
  /* padding-bottom: 4px; */
  width: 20.75vw;
  min-width: 262px;
  border-style:none ;
  /* border-bottom-style:none ; */
  outline: none;
  appearance: none;
  /* border-bottom: 1px solid #d0d0d0; */

}

.code-not-send-text {
  margin-top: 8px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  opacity: 0.8;
}
.resend-text {
  margin-top: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
cursor: pointer;
  color: #2775eb;
}
.resend-text-cont{
   justify-content: flex-end;
    display: flex;
    gap: 3px;
    white-space: nowrap;
    width: 23.75vw;
    min-width: 300px;

}
@media only screen and (min-width: 1441px){
  .code-not-send-text {
  margin-top: 0.55vw;
    font-size: 0.97vw;
    line-height: 1.25vw;
 
  }
  .resend-text {
  margin-top: 0.9vw;
    font-size: 0.97vw;
    line-height: 1.04vw;

  }

  .input-pre{

  
    font-size: 1.11vw;
    line-height: 1.25vw;
    color: #000000;
    padding-bottom: 0.27vw;
    width: 20.75vw;
    /* min-width: 300px; */
  
    /* border-bottom: 1px solid #d0d0d0; */
  
  }
  .number-props-input{
    /* padding-bottom: 0.27vw; */
    width: 20.75vw;
    /* min-width: 300px; */
    border-style:none ;
    outline: none;
    appearance: none;
    /* border-bottom: 1px solid #d0d0d0; */
  
  }
  
}
@media only screen and (max-width: 767px){
  .resend-text-cont{
    min-width: 300px;
    /* margin-left: 33.38vw !important ; */
    justify-content: flex-end;
  }
}