.ant-dropdown {
  width: 48.92vw;
  left: 43.58vw !important;
  height: auto;
  top: 13.58vw !important;
  border: 0.25vw solid #d6d6d6;
  box-shadow: 5px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 1.02vw;
}
.menu-dropdown-container {
  padding: 3.33vw 0 3.33vw 3.07vw;
  opacity: unset;
}
.menu-dropdown-container .ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
  gap: 2.3vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;

  color: #000000;
  white-space: nowrap;
  /* opacity: 0.8; */
}
