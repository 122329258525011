.active-leads-content {
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
  border-radius: 16px;

}
.actv-lds-main-cont{
  overflow-y: hidden;
  width: 48.33vw;
  height: 34.71vh;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
  border-radius: 16px; 
}


.active-leads-table{
  /* overflow-y: scroll;
  width: 48.33vw;
  height: 34.71vh;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
  border-radius: 16px; */
}



.active-leads-table {
  /* width: 100px; */
  table-layout: fixed;
  /* border-collapse: collapse;  */
}
  .active-leads-table tbody {
  display: block;
  overflow: auto;
  /* width: 696px;/ */
  height: 34.71vh;
}
.active-leads-table thead tr {
  display: flex;

}
.active-leads-table th,
.active-leads-table  td {
  padding: 0.39vw 0.69vw;
}



.active-leads-table {
  /* width: 100px; */
  table-layout: fixed;
  /* border-collapse: collapse;  */
}
  .active-leads-table tbody {
  display: block;
  overflow: auto;
  /* width: 696px;/ */
  height: 34.71vh;
}
.active-leads-table thead tr {
  display: flex;

}
.active-leads-table th,
.active-leads-table  td {
  padding: 0.39vw 0.69vw;
}



.active-leads-table {
  /* width: 100px; */
  table-layout: fixed;
  /* border-collapse: collapse;  */
}
  .active-leads-table tbody {
  display: block;
  overflow: auto;
  /* width: 696px;/ */
  height: 34.71vh;
}
.active-leads-table thead tr {
  display: flex;

}
.active-leads-table th,
.active-leads-table  td {
  padding: 0.39vw 0.69vw;
}



.active-leads-table {
  /* width: 100px; */
  table-layout: fixed;
  /* border-collapse: collapse;  */
}
  .active-leads-table tbody {
  display: block;
  overflow: auto;
  /* width: 696px;/ */
  height: 34.71vh;
}
.active-leads-table thead tr {
  display: flex;

}
.active-leads-table th,
.active-leads-table  td {
  padding: 0.39vw 0.69vw;
}

.actv-lds-main-cont::-webkit-scrollbar {
  width: 3px;
  height: 3px;

}


.active-leads-table tbody::-webkit-scrollbar {
  width: 3px;
  height: 3px;

}


.active-leads-content::-webkit-scrollbar-track-piece {
  /* background: #DADADA; */
  opacity: 0.4;
  border-radius: 2.08vw;
}

::-webkit-scrollbar-thumb {
  background: #683FDB;
  border-radius: 2.08vw;
}
.active-leads-table tr:nth-child(even) {
  background: rgba(217, 217, 217, 0.3);
}
 .active-leads-table tr:nth-child(odd) {
  background: rgba(217, 217, 217, 0.1);
}
.active-leads-table table {
  border-collapse: collapse;
}

 .active-leads-table tr {
  width: 696px;
  height: 53px;
  text-align: center;
  align-items: center;
}
.active-leads-table th {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 696px;
  height: 3.68vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  opacity: 0.8;
}
.s-no-head{
  width: fit-content !important;
  padding-left: 1.7vw !important;
}
.active-leads-table td {
  width: 696px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.8;
  padding-left: 2.01vw;
  text-align: initial;
  cursor: pointer;
  white-space: nowrap;
}



/* Pure CSS (without IE11 support): */

table th {
    /* // this is for all Safari (Desktop & iOS), not for Chrome */
    /* z-index: 1;
    opacity: 1;
    background: rgb(195, 10, 10);  */
}



.active-leads-table tr:hover td{
  /* background: #F4EEFF; */
}
.actvlds-icn:hover{
    background: #F4EEFF;
}
.communication-icon-container{
  display: flex;
  gap: 1.8vw;
  align-items: flex-start;
}
.active-leads-table .tooltip {
  /* position: relative;

  display: inline-block;
  border-bottom: 1px dotted black; */
  position: relative;
}

.active-leads-table .tooltip .tooltiptext {
  visibility: hidden;
  text-align: center;
  padding: 5px 0;
  position: absolute;
  width: 84px;
  height: 28px;
  background: #683fdb;
  border-radius: 6px;
  opacity: 1;
  bottom: 130%;
  left: -130%;
  font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 1.11vw;
line-height: 1.38vw;

color: #FFFFFF;

}

.active-leads-table .tooltip:hover .tooltiptext {
  visibility: visible;
}

.active-leads-table .tooltip1 {
  /* position: relative;

  display: inline-block;
  border-bottom: 1px dotted black; */
  position: relative;
}

.active-leads-table .tooltip1 .tooltiptext1 {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;

  /* Position the tooltip */
  /* position: ; */
  /* z-index: 1; */
  bottom: 130%;
  left: -130%;
  /* margin-left: -60px; */
}

.active-leads-table .tooltip1:hover .tooltiptext1 {
  visibility: visible;
}
/* .empty-activeleads-main-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  overflow: hidden;

} */
.empty-activeleads{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  overflow-y: hidden;
  padding: 1.38vw 0 0 0;
}
.loder-screen-activeleads {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18vw;
    /* padding: 8.38vw 0 0 0; */
}
.empty-activeleads > svg{
  /* margin-top: 2.50vh; */
}
.empty-activeleads > h1{
  margin-bottom: 0;
font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 1.52vw;
line-height: 1.66vw;
color: #333333;
opacity: 0.8;
margin-top: 2.50vh;

}
.empty-activeleads > p{
  margin-top: 0.97vh;
  margin-bottom: 0;
  /* width: 21.25vw; */
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 0.83vw;
line-height: 1.25vw;
color: #999999;
}
@media only screen and (min-width: 1441px) {
  .active-leads-table tr {
    width: 48.33vw !important;
    height: 3.68vw !important;
  }
  .active-leads-table th {
    /* background: #d9d9d9; */
    font-size: 0.83vw !important;
    line-height: 1.09vw !important;
  }
  .active-leads-table td {
    width: 48.33vw !important ;
    font-size: 0.83vw !important;
    line-height: 1.1vw !important;
  }

}
@media only screen and (max-width: 767px){

  .actv-lds-main-cont{
    /* margin-top: 4.30vw; */
    width: 87.69vw;
    height: 75.89vw;
  }
  .active-leads-table th{
    padding: 0 0 0 7.43vw;
    font-size: 12px;
    line-height: 14px;
    height: 53px;
  }
  .s-no-head{
    padding-left: 4.7vw !important;
  }
  .active-leads-table td{
    padding: 0 0 0 7.13vw;
    white-space: nowrap;
  }
  .communication-icon-container{
    gap:5.12vw;
    padding: 0 2vw 0 0;
  }
  .communication-icon-container .icon-cont > svg{
    width: 7.69vw;
    height: 7.69vw;
   }
}
