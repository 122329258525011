.heading-set-pss {
  margin-top: 72px;
  /* margin-left: 5vw; */
  width: 291px;
  height: 28px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  color: #333333;
  opacity: 0.8;
  margin-bottom: 0;
  white-space: nowrap;
}
input[type="password"]{
  font-family: 'Rubik';
font-style: normal;
font-weight: 400;

line-height: 18px;

/* letter-spacing: 4px; */

color: #000000;
/* margin-bottom: 8px; */
opacity: 0.8;
  font-size: 1.11vw;
}
.suggest-para {
  /* margin-top: 2.82vw; */
  position: relative;
  top: 0.55vw;
  /* margin-top: 5.2vw;  */
  /* margin-left: 5.3vw; */
  /* width: 339px; */
  width: 23.75vw;
  height: 36px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  opacity: 0.8;
}
.email-error{
  position: absolute;
  margin-top: -1.27vw;
  color: red;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 0.97vw;
}
.password-error{
  position: absolute;
  margin-top: -0.27vw;
  color: red;
 
  /* margin-bottom: 0; */
}
.continue-btn {
  /* margin-top: 32px;  */
  margin-top: 2.22vw;

  /* margin-left: -2vw; */
  width: 23.75vw;
  min-width: 308px;
  /* min-width: 258px; */
  height: 48px;
  background: #d6d6d6;
  border-radius: 8px;
  border-style: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}
.active-continue-btn {
  /* margin-top: 32px; */
  margin-top: 2.22vw;

  /* margin-left: 5vw; */
  width: 23.75vw;
  min-width: 300px;
  height: 48px;
  background: #d6d6d6;
  border-radius: 8px;
  border-style: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  background: #ff9900;
  cursor: pointer;
}
.do-later{
margin-top: 30px;
/* margin-left: 14.5vw; */
margin-left: 130px;
width: 77px;
height: 15px;
cursor: pointer;
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 15px;
color: #2775EB;
}
@media only screen and (min-width: 1441px){
  .heading-set-pss {
    margin-top: 5vw;
    /* margin-left: 5vw; */
    width: 20.20vw;
    height: 1.94vw;
    font-size: 1.94vw;
    line-height: 1.94vw;
   
  }
  .suggest-para {
    /* margin-top: 5.52vw; */
    /* margin-left: 5.3vw; */
    /* top: 6.20vw; */
    width: 23.54vw;
    height: 2.5vw;
    font-size: 0.97vw;
    line-height: 1.25vw;
    
  }
  .password-error{
    /* margin-top: 5.3vw; */
  }
  .continue-btn {
    /* margin-top: 2.22vw; */
    /* margin-left: 5vw; */
    /* margin-top: 6.94vw; */
    width: 23.75vw;
    height: 3.33vw;
    border-radius: 0.55vw;
    font-size: 1.11vw;
    line-height: 1.38vw;
  }
  .active-continue-btn {
    /* margin-top: 2.22vw; */
    /* margin-top: 6.94vw; */
    /* margin-left: 5vw; */
    width: 23.75vw;
    height: 3.33vw;
    border-radius: 0.55vw;
    font-size: 1.11vw;
    line-height: 1.38vw;

  }
  .do-later{
  margin-top: 2.22vw;
  margin-left:9.9vw;
  width: 5.34vw;
  height: 1.04vw;
  font-size: 1.11vw;
  line-height: 1.04vw;

  }
}
@media only screen and (max-width: 767px){
  input[type="password"]{
    line-height: 5vw;
    font-size: 4.22vw;
  }
.suggest-para {
  width: 80vw;
}
.email-error{
  font-size: 3.22vw;
  margin-top: -5vw;
}
.password-error{
  font-size: 3.22vw;
  margin-top: -2vw;
}
}