.filter-text-cont {
  display: flex;
  justify-content: space-between;
  width: 73.33vw;
  margin-top: 1.22vw;
  margin-left: 3.33vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;

  color: #333333;
}
.clear-all-filter-text{
  font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 1.11vw;
line-height: 1.04vw;
color: #2775EB;
cursor: pointer;
margin: unset;
}
.filter-text-cont > svg{
  display: none;
}
/* display: "flex",
marginLeft: "3.40vw",
gap: "1.38vw",
marginTop: "1.38vw",
width: "73.44vw", */
.clients-filter-container{
   display: flex;
   margin-left: 3.40vw;
   gap: 1.66vw;
   /* margin-top: 1.38vw; */
   width: 73.33vw;
}

.client-text-add-btn-container{
 display: flex;
 justify-content: space-between;
 /* gap:56.73vw; */
 width: 73.33vw;
 margin-top:2.33vw ;
 margin-left: 3.33vw;

}
.cients-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin-top: 1vw;
  margin-bottom: 1.11vw;
}
.add-new-clients-btn {
  width: 161px;
  height: 40px;
  background: #ff9900;
  border-radius: 8px;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 0.45vw;
}
.add-new-clients-btn:hover{
  opacity: 0.8;
}
.add-new-clients-btn-mobile{
  display: none;
}
.clietns-data-table {
  /* margin-top: 16px; */
  margin-left: 3.33vw;
  width: 73.33vw;
  /* min-width: 900px; */
  /* height: 576px; */
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
  border-radius: 16px;
}
@media only screen and (min-width: 1441px) {
  .cients-text {
    font-size: 1.66vw;
    line-height: 1.94vw;
  }
 
  .add-new-clients-btn {
    width: 11.18vw;
    height: 2.77vw;
    border-radius: 0.55vw;
    font-size: 1.11vw;
    line-height: 1.09vw;
  }
  .clietns-data-table {
    margin-top: 1.11vw;
    margin-left: 3.33vw;
    /* width: 73.33vw; */
 
    /* height: 576px; */

    border-radius: 1.11vw;
  }
}
@media only screen and (max-width: 767px){
  .client-main-container{
    /* margin-left: 2vw; */
    padding: 5.34vw 2vw  0 2vw;

  }
  .clients-filter-container{
    /* flex-direction: column; */
    display: none;
  }
  .clients-filter-container-in-mob{
    display: flex;
    margin: 6.15vw  0 6.15vw 3.40vw;

    gap: 6.15vw;
    width: 87.69vw;
    flex-direction: column;
  
  }
  .filter-text-cont{
    margin-top: 6.41vw;
    justify-content: unset;
    display: flex;
    gap: 3vw;
  }
.clear-all-filter-text{
  display: none;
}
  .filter-text-cont > svg{
    display: flex;
  }
  .client-text-add-btn-container{
     width: 90vw;
     margin-bottom: 0;
     margin-top: 0;
  }
  .add-new-clients-btn{
    display: none;
  }
  .add-new-clients-btn-mobile{
    display: flex;
    justify-content: center;
   align-items: center;
    width: 35px;
    height: 32px;
    background: #ff9900;
    border-radius: 8px;
    border: none;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #ffffff;
    cursor: pointer;
    /* margin-top: 0.45vw; */
    margin-bottom: 8px;
  }
  .cients-text{
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .clietns-data-table{
    /* width: 325px; */
    width: 89.44vw;
    overflow: hidden;
    margin-left: 0;
  }

  .clietns-data-table .table-style-one{
    overflow: auto;
  }
  .clietns-data-table .table-style-one::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  
  }
  
  .clietns-data-table .table-style-one::-webkit-scrollbar-track-piece {
    background: #DADADA;
    opacity: 0.4;
    border-radius: 2.08vw;
  }
  
  ::-webkit-scrollbar-thumb {
    /* height: 3px; */
    background: #683FDB;
    border-radius: 2.08vw;
  }


  .use-for-mobile{
    margin-left: 12px;
  }

}