/* .tag {
  text-align: center;
} */

.dash-news-cont .nes-content-cont{
  background: #FFFFFF;
  box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
  border-radius: 16px;
}
.slick-slide img {
  margin: auto;
}
.slick-dots{
 bottom: -2.66vw !important;
}

.dash-news-cont .slick-list {
  /* height: 16.56vh; */

  width: 23.33vw;
  filter: drop-shadow(0px 4px 18px rgba(116, 82, 214, 0.15));
  border-radius: 1.11vw;
}
.dash-news-cont .slick-list:hover{
  box-shadow: 0px 8px 18px rgba(116, 82, 214, 0.25);
}
.slick-dots li button:before {
  font-size: 0.83vw !important;
}
.latest-news-cont{
  width: 23.33vw !important;
  height: 1vh !important;
  left: 688px;
  top: 712px;
  
  background: #FFFFFF;
  box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
  border-radius: 16px;
  
  }

.dash-carousel-conetnt{
  width: 23.33vw !important;
  height: 15vh !important;
  background: #ffffff;
  /* border-radius: 1.11vw; */
}

@media only screen and (min-width: 1441px) {
  dash-news-cont .slick-list {
    height: 16.56vh;
    width: 23.33vw;
   
    border-radius: 1.11vw;
  }
  .slick-dots li button:before {
    font-size: 0.83vw !important;
  }

}
@media only screen and (max-width: 767px){
  .dash-news-cont .slick-list{
    width: 87.69vw;
    height: 28.97vw;
    border-radius: 4.10vw;
  }
  .dash-carousel-conetnt{
    width: 87.69vw !important;
    height: 28.97vw !important;
  }
  .slick-dots{
    bottom: -7.66vw !important;
   }
  .slick-dots li button:before{
    font-size: 3.07vw !important;
  }
}
