.fund-details-main-container {
  padding: calc(8px + 2.76vw);
  /* padding: 48px; */
}
.fund-logo {
  /* width: 4.72vw;
    height: 4.72vw; */
  margin-top: 1.25vw;
}
.fund-name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  margin-bottom: 0;
  margin-top: 0.55vw;
}
.fund-type-container {
  display: flex;
  gap: 1vw;
  margin-top: 0.55vw;
}
.fund-type {
  margin-bottom: 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.18vw;
  color: #000000;
  opacity: 0.6;
}
.fundShare-bookmark-icon-container {
  display: flex;
  gap: 1.59vw;
}
.fundShare-bookmark-icon-container-inMob {
  display: none;
}
.fund-return-cont {
  display: flex;
  margin-top: 2.22vw;
  gap: 1vw;
}
.yearly-return-per {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.38vw;
  line-height: 1.25vw;
  color: #3ea400;
  margin-bottom: 0;
}
.yearly-return-per-negative {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.38vw;
  line-height: 1.25vw;
  color: red;
  margin-bottom: 0;
}
.fund-return-cont span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.18vw;
  color: #000000;
  opacity: 0.6;
}
.one-day-return {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.25vw;
  opacity: 0.8;
  margin-bottom: 0;
}

.about-fund-cont {
  margin-top: 2.22vw;
}
.about-fund-para {
  margin-bottom: 0;
  /* width: 920px; */
  width: 63.89vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.18vvw;
  color: #000000;
  opacity: 0.6;
}
.fund-market-detail-cont {
  display: flex;
  margin-top: 2.22vw;
  gap: 10.69vw;
}
.fund-market-detail-data > h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 0.83vw;
  line-height: 0.97vw;
  color: #000000;
  opacity: 0.8;
  white-space: nowrap;
  margin-bottom: 0.55vw;
}
.fund-market-detail-data > h6 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.18vw;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 0;
}
.fund-graph-cont {
}
.fund-performance-cont {
  margin-top: 3.33vw;
}

.fund-performance-cont > h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  white-space: nowrap;
  margin-bottom: 0;
}
.fund-pros-cons-cont {
  margin-top: 3.33vw;
}
.fund-pros-cons-cont > h1 {
  margin-bottom: 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  opacity: 0.8;
}
.pros-cons-para-cont{
  margin-top: 1.66vw;
}
.fund-pros-cons-cont p {
  margin-bottom: 0;
  /* margin-top: 1.66vw; */
  width: 64.44vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.45vw;
  color: #000000;
  opacity: 0.6;
  
}
.calculator-cont {
  margin-top: 3.33vw;
  margin-bottom: 0;
}
.calculator-cont > h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  margin-bottom: 0;
  opacity: 0.8;
}
.similar-fund-cont {
  /* margin-top: 3.33vw; */
}
.similar-fund-cont > h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  margin-bottom: 0;
}
.current-investment-cont {
  margin-top: 3.54vw;
}
.current-investment-cont > h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  white-space: nowrap;
}
.current-investment-data {
  display: flex;
  margin-top: 2.22vw;
  gap: 10.69vw;
}
.client-list-cont {
  margin-top: 3.33vw;
}
.client-list-cont > h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  margin-bottom: 0;
  white-space: nowrap;
}
.graph-btn-cont {
  display: flex;
  margin-top: 2.82vw;
  margin-left: 13.19vw;
  gap: 5vw;
  /* gap: 2.70vw; */
}
.graph-btn-active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.46vw;
  height: 2.15vw;
  background: linear-gradient(95deg, #673fda 30.88%, #a05ad8 120.98%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1.11vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.18vw;
  border-style: none;
  cursor: pointer;
  color: #ffffff;
}
.graph-btn-inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.55vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.18vw;
  color: #000000;
  opacity: 0.6;
  border-style: none;
  background-color: white;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .fund-details-main-container {
  }
  .fund-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5.12vw;
  }
  .fund-logo > img {
    width: 45px !important;
    height: 45px !important;
  }

  .fund-name-type-cont {
    /* display: none; */
  }
  .fund-name {
    font-size: 18px;
    line-height: 21px;
    margin-top: 1.79vw;
  }
  .fund-type-container {
    margin-top: 1.02vw;
  }
  .fund-type {
    font-size: 12px;
    line-height: 16px;
  }

  .fundShare-bookmark-icon-container {
    /* display: none; */
  }
  .fundShare-bookmark-icon-container > .bookmark-share-icon-cont {
    display: none;
  }
  .fundShare-bookmark-icon-container-inMob {
    display: flex;
    gap: 6.6vw;
    margin-right: 3vw;
  }
  .fundShare-bookmark-icon-container-inMob svg {
    width: 28px;
    height: 28px;
  }
  .fund-return-cont {
    margin-top: 2.56vw;
    gap: 3.07vw;
  }
  .yearly-return-per {
    font-size: 16px;
    line-height: 18px;
  }
  .fund-return-cont span {
    font-size: 12px;
    line-height: 18px;
  }
  .one-day-return {
    font-size: 12px;
    line-height: 18px;
  }
  .about-fund-cont {
    /* display: none; */
    margin-top: 5.12vw;
  }
  .about-fund-para{
    font-size: 14px;
    line-height: 21px;
    width: 87.43vw;
  }
  .fund-market-detail-cont {
    /* display: none; */
    flex-wrap: wrap;
    flex-direction: column;
    gap: unset;
    row-gap: 6.15vw;
    /* column-gap: 10vw; */
    height: 26vw;
    margin-top: 5.12vw;
  }

  .fund-market-detail-data > h5{
    font-size: 12px;
    line-height: 14px;
    opacity: 0.6;
  }
  .fund-market-detail-data > h6{
    font-size: 14px;
   line-height: 17px;
  }
  .fund-graph-cont {
    /* display: none; */
  }
  .graph-for-fund-detail{
    height: 49.23vw !important;
  }
  .graph-for-fund-detail > canvas{
    width: 90vw !important;
    height: 49.23vw !important;
  }
  .graph-btn-cont{
    /* display: none; */
    margin-top: 7.69vw;
    margin-left: unset;
    justify-content: space-between;
  }
  .graph-btn-active{
    width: 16.92vw;
    height: 6.66vw;
    font-size: 12px;
    line-height: 14px;
    border-radius: 4.10vw;
  }
  .graph-btn-inactive{
    font-size: 12px;
    line-height: 14px;
    margin-top: 1.55vw;
  }
  .fund-performance-cont {
    /* display: none; */
    margin-top: 12.30vw;
  }
  .fund-performance-cont h1{
    font-size: 18px;
    line-height: 18px;
  }
  .fund-performance-cont .fund-market-detail-cont{
    flex-direction: unset;
    justify-content: space-between;
    height: auto;
  }
  .fund-pros-cons-cont {
    /* display: none; */
    margin-top: 12.30vw;
  }
  .fund-pros-cons-cont > h1{
    font-size: 18px;
    line-height: 18px;
  }
  .pros-cons-para-cont{
    margin-top: 4.10vw;
  }
  .fund-pros-cons-cont p{
    font-size: 14px;
    line-height: 17px;
    width: auto;
  }
  .calculator-cont {
    /* display: none; */
    margin-top: 12.30vw;
  }
  .calculator-cont .wealth-calculator{
    padding-bottom: unset;
  }

  .calculator-cont > h1{
    font-size: 18px;
    line-height: 18px;
    opacity: 0.8;
  }
  .calculator-cont .wealth-calculator .ant-slider-mark{
    top :20px
  }
  .similar-fund-cont {
    /* display: none; */
    margin-top: 12.82vw;
  }
  .similar-fund-cont > h1{
    font-size: 18px;
   line-height: 18px;
   opacity: 0.8;
  }
  .current-investment-cont {
    /* display: none; */
    margin-top: 12.30vw;
  }
  .current-investment-cont > h1{
    font-size: 18px;
    line-height: 18px;
   opacity: 0.8;
  }
  .current-investment-data{
      margin-top: 4.10vw;
      flex-direction: column;
      flex-wrap: wrap;
      gap: unset;
      height: 27vw;
      row-gap: 6.92vw;
     
  }
  .client-list-cont {
    /* display: none;  */
    margin-top: 12vw;
  }
  .client-list-cont > h1{
    font-size: 18px;
    line-height: 18px;
    opacity: 0.8;
  }
}
