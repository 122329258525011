.marketing-main-cont {
  /* padding: calc(0.55vw + 2.26vw); */
  padding-top: 2.8%;
  padding-left: 4.2%;
  padding-bottom: 1%;
}

.naviagte-to-dashboard-cont{
  display: flex;
  align-items: baseline;
  gap: 0.3vw;
}
.marketing-heading-cont{
  margin-top: 1.23vw;
}
.marketing-heading-cont h1 {
  margin: 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
}
.toggel-btn-cont {
  display: flex;
  margin-top: 2.22vw;
  gap: 1vw;
}
.btn-type {
  width: 14.58vw;
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.38vw;
  line-height: 2.08vw;
  letter-spacing: 0.1px;
  color: #333333;
  margin: 0;
  cursor: pointer;
}
.btn-type-active {
  width: 14.58vw;
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1.38vw;
  line-height: 2.08vw;
  letter-spacing: 0.1px;
  color: #683fdb;
  margin: 0;
  cursor: pointer;
  border-bottom: 0.2vw solid #683fdb;
}
.filter-cont {
  display: flex;
  margin-top: 3.33vw;
  gap: 1.66vw;
}
.filter-cont .expand-cont {
  position: absolute;
  z-index: 1;
}
.card-cont {
  display: flex;
  flex-wrap: wrap;

  gap: 1.66vw;
  row-gap: 10vw;
  margin-top: 3.33vw;
  margin-bottom: 11.2vw;
}

/* ---------------Card js------------css---------------- */
.card {
  position: relative;
  width: 23.33vw;
  height: 10.55vw;
  /* left: 320.55vw;
  top: 326px; */

  border-radius: 0.55vw;
}

.card:hover{
  cursor: pointer;
}

.card:hover {
  -webkit-filter: brightness(60%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
/* .card:hover:after {
  opacity:0.3;
} */



.card iframe {
  border-radius: 0.55vw;
}
.card img {
  border-radius: 0.55vw;
}
.play-icn-cnt > svg {
  position: absolute;
  display: none;
  width: 4.44vw !important;
  height: 4.44vw !important;
  left: 9.44vw;
  top: 3.04vw;
}

.bookMark {
  position: absolute;
  width: 1.66vw;
  height: 1.66vw;
  right: 0.55vw;
  top: 0.55vw;
  background: #000000;
  opacity: 0.8;
  text-align: center;
  border-radius: 0.27vw;
}
.bookMark > svg {
  cursor: pointer;
  width: 0.69vw !important;
  height: 0.9vw !important;
  margin: 0.34vw;
  /* z-index: 1; */
}
.downlod-and-share-icon-cont {
  /* display: flex;
  justify-content: space-between;
  position: relative; */
}
.dl-icn-cont {
  position: absolute;
  width: 1.66vw;
  height: 1.66vw;
  right: 2.77vw;

  /* top: 110.55vw; */
  bottom: 0.55vw;
  background: #000000;
  opacity: 0.8;
  text-align: center;
  border-radius: 0.27vw;
}
.dl-icn-cont > svg {
  width: 0.69vw !important;
  height: 0.9vw !important;
  margin: 0.34vw;
}
.share-icn-cont {
  position: absolute;
  width: 1.66vw;
  height: 1.66vw;
  right: 0.55vw;
  bottom: 0.55vw;
  background: #000000;
  opacity: 0.8;
  text-align: center;
  border-radius: 0.27vw;
}
.share-icn-cont > svg {
  /* width: 10px !important;
  height: 13px !important; */
  margin: 0.34vw;
  cursor: pointer;
}
.card h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 1.66vw;
  letter-spacing: 0.1px;
  color: #333333;
  opacity: 0.8;
  margin-top: 1.11vw;
  margin-bottom: 0;
}
.video-card-cont h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 1.66vw;
  letter-spacing: 0.1px;
  color: #333333;
  opacity: 0.8;
  margin-top: 0.72vw;
  margin-bottom: 0;
}
.card p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 0.97vw;
  line-height: 1.38vw;
  color: #666666;
  margin-top: 0.55vw;
}
.card h6 {
  font-family: "Rubik";
  margin-top: 1.01vw;
  font-style: normal;
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 1.18vw;
  color: #666666;
  margin-bottom: 0;
}
.date-heading {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.83vw;
  line-height: 1.18vw;
  color: #666666;
}

/* ---------------Share modal----------csss------------- */
.marketing-share-content-modal {
  width: 48.75vw !important;
  height: 29.93vw !important;
  background: #ffffff;
  border-radius: 1.11vw;
}
.marketing-share-content-modal .ant-modal-content {
  width: 48.75vw !important;
  height: 29.93vw !important;
  box-shadow: none;
  border-radius: 1.11vw;
}
.marketing-share-content-modal .ant-modal-body {
  padding: 1.66vw;
}
.content-theme-cont {
  /* margin-top: 50px; */
  width: 44.37vw;
  height: auto;
  border-radius: 0.55vw;
}
.content-theme-cont > h1 {
  margin-top: 1.49vw;
  margin-left: 1vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 28px;
  color: #333333;
}
/* .content-theme-cont > img{
  border-radius: 0.55vw;
} */
.share-icon-cont {
  display: flex;
  margin-top: 3.54vw;
  gap: 2.38vw;
}
.share-icon-cont svg {
  cursor: pointer;
}
.or-text-cont {
  display: flex;
  justify-content: center;
  margin-top: 2.59vw;
  margin-bottom: 0;
}
.or-text-cont > h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 1.11vw;
  line-height: 36px;
  color: #000000;
}
.marketing-copy-url-container .copy-url-container {
  margin-top: 0;
}
.marketing-share-content-modal .ant-modal-footer {
  border: none;
  padding: 0;
  display: none;
}
.marketing-share-content-modal .ant-btn,
.ant-btn:active,
.ant-btn:focus {
  /* display: none; */
}
.share-dl-btn-cont {
  display: flex;
  justify-content: flex-end;
  gap: 2.43vw;
  margin-top: 1.66vw;
}
.share-dl-btn-cont > svg {
  width: 5.9vw;
  height: 5.9vw;
  cursor: pointer;
}
.share-dl-text-cont {
  display: flex;
  margin-top: 0.69vw;
  gap: 4.23vw;
  width: 43vw;
  justify-content: flex-end;
}
.share-dl-text-cont > h6 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.31vw;
  color: #000000;
  opacity: 0.6;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .marketing-main-cont {
    padding-left: 5.2%;
  }
  .naviagte-to-dashboard-cont{
    display: flex;
    align-items: baseline;
    gap: 0.3vw;
  }
  .marketing-heading-cont {
    margin-top: 7.94vw;
  }
  .marketing-heading-cont h1{
    font-size: 18px;
    line-height: 18px;
    opacity: 0.8;
  }
  .toggel-btn-cont {
    /* display: none; */
    margin-top: 4.10vw;
    overflow-x: scroll;
    height: 12vw;
  }
  .btn-type{
    width: 51.58vw;
    font-size: 16px;
    line-height: 30px;
  }
  .btn-type-active{
    width: 51.58vw;
    font-size: 16px;
    line-height: 30px;

    border-bottom: 0.51vw solid #683fdb;
  }
  .filter-cont {
    /* display: none; */
    flex-direction: column;
    gap: 6.15vw;
  }
  .filter-cont .expand-cont{
    display: flex;
    width: 87.69vw;
    height: 65vw;

  }
  
  .filter-cont .search-cont{
    justify-content: space-between;
    align-items: unset;
  }
  .filter-cont .search-cont img{
     margin: 1vw 3vw 1vw 0;
     /* padding: 3vw 3vw 3vw 0; */
     /* padding-right: 3vw; */
     width: 7.15vw;
     height: 7.15vw;
  }
  .filter-cont .expand-cont-value{
    height: 100%;
    margin-right: 4.10vw;
    display: flex;
flex-direction: column;
gap: 4.12vw;
    /* overflow: unset; */
  }
  .filter-cont .expand-cont-value > div{
    /* margin: unset;
     */
  }
  .filter-cont .checkbox-filter-cont>.click-div{
    width: 87.69vw;
  }
  .card-cont {
    /* display: none; */
    flex-direction: column;
    margin-top: 8.20vw;
    row-gap: 30.20vw;
  }
  .card{
  
    width: 87.69vw;
    height: 38.97vw;
    border-radius: 2.05vw;
  }
  .card img{
    border-radius: 2.05vw;
  }
  .card iframe{
    border-radius: 2.05vw;
  }
  .bookMark{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.26vw;
    height: 6.15vw;
    right: 2.05vw;
    top:2.05vw;
    border-radius: 0.55vw;
  }
  .bookMark > svg{
    width: 2.61vw !important;
    height: 3.33vw !important;

  }
  .share-icn-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.26vw;
    height: 6.15vw;
    right: 2.05vw;
    bottom:2.05vw;
    border-radius: 0.55vw;
  }
  .share-icn-cont > svg{
    width: 3.61vw !important;
    height: 4.33vw !important;
  }
  .card h1{
    font-size: 18px;
    line-height: 24px;
    margin-top: 4.10vw;
  }
  .card h6{
    font-size: 12px;
   line-height: 17px;
   /* margin-top: 4.10vw; */
  }
  .date-heading{
    font-size: 12px;
   line-height: 17px;
  }
  .marketing-share-content-modal .ant-modal-content{
    width: 87.69vw !important;
    height: unset !important;
    border-radius:4.10vw !important
 
  }
  .marketing-share-content-modal .ant-modal-body{
    padding: 8.20vw 0 8.20vw 5.12vw;
  }
  .marketing-share-content-modal{
    width: 87.69vw !important;
       height: auto;
       border-radius:4.10vw !important
  }
  .content-theme-cont > h1{
    font-size: 24px;
    line-height: 28px;
  }
  .share-icon-cont{
    flex-wrap: wrap;
    margin-top: 12.30vw;
    gap:5.12vw
  }
  .share-icon-cont svg{
    width: 77.64px;
   height: 77.64px;
  }
  .or-text-cont > h1{
    font-size: 16px;
line-height: 36px;
  }
  .marketing-share-content-modal .ant-modal-close-x svg{
    font-size: 20px;
    line-height: 25px;
  }
}
