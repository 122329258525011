.addnewclients-main-container {
  display: flex;
  flex-direction: column;
  margin-left: 3.33vw;
  margin-top: 2.77vw;
}
.new-client-text {
  width: 120px;
  height: 28px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin-bottom: 0;
}
.basic-detail-text {
  /* width: 123px; */
  /* margin-top: 40px; */
  height: 24px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 0;
  margin-top: 2.22vw;
}
.input-contianer {
  display: flex;
  flex-direction: column;
  margin-top: 2.22vw;
  gap: 2.22vw;
}
.input-contianer .input-prefix {
  margin-top: 0;
}
.input-contianer .reqst-btn-active {
  margin-top: 0;
}
.input-contianer .reqst-btn {
  margin-top: 0;
}

.gmail-number-text-pss-input {
  /* margin-top: 42px; */
  border-style: none;
  outline: none;
  width: 336px;
  border-bottom: 1px solid rgba(208, 208, 208, 0.5);
  padding-bottom: 8px;
  /* opacity: 0.5; */
}
input[type="gmail"] {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  opacity: 0.8;
}
.error-msg {
  position: relative;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: -9vw;
  color: red;
  opacity: 0.6;
}
input[type="radio"] {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.8;
}
.gmail-number-text-pss-input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  opacity: 0.2;
}
.radio-inputs,
input[type="radio"] {
  font-size: 0.97vw;
  line-height: 1.18vw;
  accent-color: #683fdb;
}
.conde-notsend-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  opacity: 0.8;
}
.resend-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #2775eb;
}
/* -----------------NewClientsPersonal-css------------- */
.gender-occupation-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  opacity: 0.8;
}
.gender-occupation-text-radio {
  accent-color: green;
}
@media only screen and (min-width: 1441px) {
  .new-client-text {
    width: 8.33vw;
    height: 1.94vw;
    font-size: 1.66vw;
    line-height: 1.94vw;
  }
  .basic-detail-text {
    /* width: 123px; */
    /* margin-top: 2.77vw; */
    height: 1.66vw;
    font-size: 1.38vw;
    line-height: 1.66vw;
  }
  .gmail-number-text-pss-input {
    /* margin-top: 2.91vw; */
    width: 23.33vw;
    padding-bottom: 0.55vw;
    /* opacity: 0.5; */
  }
  .gmail-number-text-pss-input::placeholder {
    font-size: 1.11vw;
    line-height: 1.25vw;
  }
  input[type="gmail"] {
    font-size: 1.11vw;
    line-height: 1.25vw;
  }
  .error-msg {
    font-size: 0.97vw;
    line-height: 1.18vw;
    margin-top: 0.97vw;
  }
  .radio-inputs,
  input[type="radio"] {
    font-size: 0.97vw;
    line-height: 1.18vw;
    accent-color: #683fdb;
  }
  .conde-notsend-text {
    font-size: 0.97vw;
    line-height: 1.25vw;
  }
  .resend-text {
    font-size: 0.97vw;
    line-height: 1vw09;
  }
  .gender-occupation-text {
    font-size: 1.11vw;
    line-height: 1.25vw;
  }
}
@media only screen and (max-width: 767px) {
  .addnewclients-main-container {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-top: 0;
    padding: 24px;
  }
  .new-client-text {
    font-size: 18px;
    line-height: 18px;
  }
  .basic-detail-text {
    /* margin-top: 24px; */
    margin-bottom: -16px;
    font-size: 14px;
    line-height: 17px;
  }
  .input-contianer {
    margin-top: 32px;
    gap: 22px;
  }
  .error-msg {
    /* margin-top: px; */
    margin-top: -113px;
  }
  .gmail-number-text-pss-input {
    min-width: 300px;
    width: 290px;
  }
}
