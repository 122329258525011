.signup-container{
  display: flex;
  flex-direction: column;
  margin-left: 5vw;
}
.sign_up_text {
  width: 102px;
  margin-top: 72px;
  /* margin-left: 72px; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  color: #333333;
  opacity: 0.8;
  margin-bottom: 0;
}
.input-prefix {
  padding-bottom: 4px;
  margin-top: 32px;
  width: 23.75vw;
  min-width: 331px;
  /* min-width: 250px; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  /* opacity: 0.2; */
  border-bottom: 1px solid #d0d0d0;
}
.number-input {
   /* padding-bottom: 4px;
     margin-top: 35px; 
    margin-left: 72px;
      width: 23.75vw; */
    min-width: 331px;
    min-width: 250px;
    /* border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden; */
  border-style: none;
  outline: none;
  appearance: none;
  /* border-bottom: 1px solid #d0d0d0; */
  /* opacity: 0.5;  */
}
input::placeholder {
  height: 2vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  opacity: 0.2;
}
input[type="number"] {
  font-size:16px
  /* color: #000000; */
}

.reqst-btn {
  /* min-width: 258px; */
  min-width: 339px;
  margin-top: 32px;
  width: 23.75vw;
  height: 48px;
  /* margin-left: 5vw; */
  background: #d6d6d6;
  border-radius: 8px;
  border: navajowhite;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}
.reqst-btn-active {
  /* min-width: 258px; */
  min-width: 339px;
  margin-top: 32px;
  width: 23.75vw;
  height: 48px;
  /* margin-left: 5vw; */
  background: #d6d6d6;
  border-radius: 8px;
  border: navajowhite;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  background: #ff9900;
  cursor: pointer;
}
.signIn-text{
  margin-top: 10px;
  margin-left: 280px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #2775eb;
  cursor: pointer;
}
@media only screen and (min-width: 1441px) {
  .sign_up_text {
    width: 7.08vw;
    margin-top: 5vw;
    /* margin-left: 5vw; */
    font-size: 1.94vw;
    line-height: 1.94vw;
  }
  .input-prefix {
    padding-bottom: 0.27vw;
    margin-top: 2.22vw;
    /* margin-left: 5vw; */
    font-size: 1.11vw;
    line-height: 1.25vw;
    width: 23.75vw;
    height: 2vw;
    /* border-bottom: 1px solid #d0d0d0; */
  }
  .number-input {
    /* padding-bottom: 0.27vw;
    margin-top: 2.43vw; */
    /* margin-left: 5vw; */
    /* width: 23.75vw;
    height: 2vw; */
    /* min-width: ; */
  }
  input::placeholder {
    font-size: 1.11vw;
    line-height: 18px;
  }
  input[type="number"] {
    font-size:1.11vw
    /* color: #000000; */
  }
  .reqst-btn {
    margin-top: 2.22vw;
    width: 23.75vw;
    height: 3.33vw;
    /* margin-left: 5vw; */
    border-radius: 0.55vw;
    font-size: 1.11vw;
    line-height: 1.38vw;
  }
  .reqst-btn-active {
    margin-top: 2.22vw;
    width: 23.75vw;
    height: 3.33vw;
    /* margin-left: 5vw; */
    border-radius: 0.55vw;
    font-size: 1.11vw;
    line-height: 1.38vw;
  }
  .signIn-text{

    margin-left: 19.44vw;
 
    font-size: 0.97vw;
    line-height: 1.11vw;
  
  }
}
@media only screen and (max-width: 767px){
  .signup-container{
    margin-left: 9vw;
  }
  input::placeholder{
    font-size: 4.22vw;
    height: 5vw;
  }
  .input-prefix{
    /* width: 23.75vw; */
    min-width: 300px;
  }
  .reqst-btn{
    min-width: 300px;
  }
  .reqst-btn-active{
    min-width: 300px;
  }
  .signIn-text{
    margin-left: 70.70vw;
  }
}