.transaction-main-cont {
  margin-left: 3.33vw;
  margin-top: 2.22vw;
}
.filter-heading-cont {
  display: flex;
  justify-content: space-between;
  width: 73.33vw;
  margin-top: 1.25vw;

}
.filter-heading-cont > svg {
  display: none;
}

.checkbox-filter-container {
  display: flex;
  gap: 1.66vw;
  flex-wrap: wrap;
  width: 73.33vw;
  row-gap: 2.24vw;
}
.transaction-heading {
  display: flex;
  justify-content: space-between;
  width: 73.33vw;
  /* min-width: 900px; */
  margin-top: 3.33vw;
  margin-bottom: 2.22vw;
}
.transaction-heading > h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  margin-bottom: 0;
}
.trasaction-table-cont .table-style-one tr:hover td{
  background: unset;
}

.trasaction-table-cont {
  /* margin-top: 3.33vw; */
  width: 73.33vw;
  /* min-width: 900px;` */
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
  border-radius: 1.11vw;
}
.pagination-in-mob{
  display: none;
}
@media only screen and (max-width: 767px) {
  .transaction-main-cont {
    margin-left: 5.22vw;
    margin-top: 5.22vw;
  }
  .transaction-heading{
    min-width: 0;
  }
  .transaction-heading > h1 {
    font-size: 4vw;
    line-height: 5vw;
  }
  .trasaction-table-cont {
    min-width: 0;
    /* width: 325px; */
    width: 89.44vw;
    overflow: scroll;
    border-radius: 16px;
    /* height: 535px; */
    overflow:hidden;
    /* overflow-anchor: unset; */
    /* display: none; */
  }
  .trasaction-table-cont .table-style-one{
    overflow: auto;
  }
  .trasaction-table-cont .table-style-one::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  
  }
  
  .trasaction-table-cont .table-style-one::-webkit-scrollbar-track-piece {
    background: #DADADA;
    opacity: 0.4;
    border-radius: 2.08vw;
  }
  
  ::-webkit-scrollbar-thumb {
    /* height: 3px; */
    background: #683FDB;
    border-radius: 2.08vw;
  }



  .filter-heading-cont > svg {
    display: flex;
  }
  .filter-heading-cont {
    /* display: none; */
    /* width:0; */
    justify-content: unset;
    margin-top: 6.41vw;
    display: flex;
    gap: 3vw;
  }
  .filter-heading-cont > svg{
    width: 18.17px;
    height: 20px;
  }
  .filter-heading {
    font-size: 14px;
    line-height: 15px;
    margin-top: 3px;
    color: #2775eb;
  }
  .checkbox-filter-container {
    display: none;
  }
  .checkbox-filter-container-in-mob{
    display: flex;
    gap: 6.15vw;
    flex-wrap: wrap;
    width: 87.69vw;
    margin: 6.15vw 0 6.15vw  0;
    /* row-gap: 2.24vw; */
  }
  .pagination-in-mob{
    display: flex;
   /* justify-content: center */
   /* margin-left: 15px; */
  }
  .pagination-in-mob .pageNumbers{
    display: flex;
    margin-left:3px ;
    /* margin-top:-5px ; */

  }
  .tooltip-icn-cont > svg{
    width: 16px;
    height: 16px;
  }

  .ant-tooltip{
     display: none
  }
}
