/* .drawer-menu-container{
    display: none;
} */
.hambergur-menu-drawer{
    display: none;
}
@media only screen  and (max-width: 767px){
    .hambergur-menu-drawer{
        display: flex;
    }
   .hambergur-menu-drawer > .ant-drawer-content-wrapper{
        width: 65vw  !important;
   }
   .hambergur-menu-drawer .ant-drawer-header{
    display: none;
   }
}