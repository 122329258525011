
*{
  margin: 0;
  padding: 0;
}







/* --------------new css------------ */
.sideBar-container {
  width: 19.44vw;
  height: 100vh;
  position: fixed;
  /* opacity: 0.2; */
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 4px 0px 15px rgba(0, 0, 0, 0.15);
}
.logo-png {
  margin-top: 3.77vh;
  margin-left: 2.25vw;
}
.organization-name {
  margin-top: 0.97vh;
  margin-left: 2.36vw;
  width: 11.18vw;
  height: 1.70vh;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 0.83vw;
  line-height: 1.70vh;
  color: #333333;
}
.sideBar-options-contianer {
  display: flex;
  flex-direction: column;
  gap: 2.5vh;
  margin-top: 6.82vh;
  /* margin-left: 69px; */

}
.sideBar-options-contianer 
a {
  display: flex;
  align-items: center;
  gap: 0.97vw;
  /* color: #d9d9d9; */
  /* margin-left: -5px; */
  margin-left: 2.36vw;
  width: 13.88vw;
  /* min-width: 152px; */
 /* height: 6.63vh; */
 height: 3.63vw;
  padding: 16px;
  /* padding-top: 1.94vh;. */
    padding-left: 1.29vw;
  text-decoration: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 1.11vw;
  line-height: 1.31vw;
  color: #333333;
}
/* .sideBar-active-option > svg path {
  fill: #FFFFFF;
} */
.sideBar-options-contianer a:hover{
  background: rgb(104,63,219 ,0.05);
/* opacity: 0.05; */
border-radius: 0.55vw;
/* color: #cb2828 !important; */
}
.sideBar-active-option{
  /* align-items: center; */
/* width: 200px;
height: 52px;
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px; */
color: #FFFFFF !important;
background: linear-gradient(90deg, #683FDB 0%, #A05AD8 100%);
border-radius: 0.55vw;

}
.sideBar-options-contianer .sideBar-active-option:hover{
  color: #FFFFFF !important;
background: linear-gradient(90deg, #683FDB 0%, #A05AD8 100%);
border-radius: 0.55vw;

}
/* a > svg:active{
  fill: white;
} */
/* .sideBar-active-option a:active:hover{
  background-color: #2775eb;
} */
a:active {
  color: #ffffff;
 
}
a:hover{
  color: #333333;
  
}
/* --------------------------------Main-Container------- -------------------------*/
/* .dashboard-main-container {
  margin-left: 286px;
} */
.serach-profile-container{
  display: flex;
  justify-content: space-between;
  margin-left: 3.33vw;
  width: 74.9vw;
}
.search-bar {
  margin-top: 2.92vh;
  /* margin-left: 48px; */
  width: 48.33vw;
  height: 4.87vh;
  background: rgba(214, 214, 214, 0.2);
  outline: none;
  border-radius: 32px;
  border-style: none;
  text-indent: 25px;
  padding: 8px;
  /* visibility: hidden; */
}
.search-bar::placeholder {
  /* padding-left: 18px; */
  /* padding-top: 8px !important; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.97vw;
  line-height: 2.07vh;
  color: #000000;
  opacity: 0.8;
}
/* // style={{
  //   display: "flex",
  //   flexDirection: "row",
  //   gap: "0.48vw",
  //   alignItems: "center",
  //   marginTop: "3.41vh",
  // }} */
.profile-container{
 display: flex;
 flex-direction: row;
 gap: 0.48vw;
 align-items: center;
 margin-top: 3.41vh;
}
.client-name {
  /* width: 122px; */
  /* height: 19px; */
  white-space: nowrap;
  /* margin-top: 35px; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 2.31vh;
  color: #333333;
  cursor: pointer;
  margin-bottom: 0;
}
.profile-image-container{
  display: flex;
  flex-direction: row;
  gap: 1.11vw;
  margin-top:1.94vw,
}
.profile-image-container >svg{
  display: none;
}
.profile-img {
  width: 2.22vw;
  height: 2.22vw;
  /* background: #d9d9d9; */
  border-radius: 50%;
  cursor: pointer;
}
.icon-cont-inside-profile{
  display: flex;
  align-items: center;
  gap:1.3vw;
  margin-left: 0.8vw;
}
.icon-cont-inside-profile > svg{
  cursor: pointer;
  width: 1.94vw;
height: 1.94vw;
}
.dashborad-top-card-container{
  display: flex;
  flex-direction: row;
  gap: 1.66vw;
  margin-top: 3.34vh;
  margin-left: 3.33vw;
}
.activeleads-upcomingevents-container{
  display: flex;
  gap: 1.66vw;
  margin-left: 3.33vw;
  margin-top: 3.89vh;
}
.dashborad-bottom-card-container{
  display: flex;
  margin-left: 3.33vw;
  gap: 1.66vw;
  margin-top: 4.43vh;
}
.offer-news-cont{
  display: flex;
  gap: 1.66vw;
}
.managerdetail-main-container{
  display: flex;
  flex-direction: column;
  width: 47.11vw;
}
.rm-profile-detail-cont{
  display: flex;
  gap: 1vw;
  padding: 1vw;
}
.active-lead-text {
  width:9.44vw;
  height: 3.41vh;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.44vw;
  line-height: 3.41vh;
  color: #333333;
  margin-bottom: 2vh;
}
.view-all-text {
  margin-top: 0.97vh;
  width: 10.76vw;
  height: 1.82vh;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.82vh;
  color: #2775eb;
  cursor: pointer;
  padding-left: 6.25vw;
}

.abc-text {
  width: 3.40vw;
  height: 3.41vh;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.44vw;
  line-height: 3.41vh;
  white-space: nowrap;
  color: #333333;
  margin-bottom: 2vh;
}
.active-leads-content {
  /* overflow:auto;
  width: 48.33vw;
  height: 34.71vh;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
  border-radius: 16px; */
}
.active-leads-content:hover{
  /* overflow-y: scroll; */
}
.graph-container{
  text-align: center;
  align-items: center;
  /* margin-top: 1.94vh; */
/* width: 23.33vw;
height: 34.71vh; */
background: #FFFFFF;
box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
border-radius: 16px;
overflow: hidden;

/* overflow-y: scroll; */
}

.active-inactive-text{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-size: 0.83vw;
  line-height: 2.67vh;    
  color: #000000;
  opacity: 0.8;
}
.total-clients-text{
  margin: auto;
    margin-top: -24.36vh;
  width:3.47vw;
height: 8.03vh;
font-family: 'Rubik';
font-style: normal;
font-weight: 300;
font-size: 1.11vw;
line-height: 2.67vh;
color: #000000;
opacity: 0.8;
}
span{

  font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 1.11vw;
line-height: 2.67vh;
color: #000000;
}
.offer-text{
/* width: 68px;
height: 28px; */
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 1.44vw;
line-height: 3.41vh;
color: #333333;
white-space: nowrap;
margin-bottom: 2vh;

}
.my-site-text{
width: 5.48vw;
height: 3.41vh;
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 1.44vw;
line-height: 3.41vh;
color: #333333;
white-space: nowrap;
margin-bottom: 2vh;
}
.profile-img-container-rm{

width: 5.55vw;
height: 5.55vw;
border-radius: 50%;
/* background: #D9D9D9; */
}

.manager-name{
  /* position: absolute;
left: 80.42%;
right: 11.04%;
top: 81.33%;
bottom: 16%; */

font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 1.25vw;
line-height: 1.66vw;
letter-spacing: 0.1px;
color: #333333;
opacity: 0.8;
margin-bottom: 0;
white-space: nowrap;
}
.manager-type{
  /* position: absolute;
left: 80.42%;
right: 11.46%;
top: 84.22%;
bottom: 14%; */

font-family: 'Rubik';
font-style: normal;
font-weight: 300;
font-size: 0.83vw;
line-height: 1.38vw;
color: #666666;
}
.contact-text{
/* 
position: absolute;
left: 80.42%;
right: 6.6%;
top: 87.33%;
bottom: 10.33%; */

font-family: 'Rubik';
font-style: normal;
font-weight: 300;
font-size: 0.83vw;
line-height:1.38vw;
color: #666666;
margin-bottom: 0;
}
.manager-conatct-data {
font-family:'Rubik';
font-style: normal;
font-weight: 500;
font-size: 0.83vw;
line-height: 1.38vw;
color: #666666;
}
.offer-cont{
  margin-top: -0.9vw;
  width: 24.33vw;
  height: 18.75vh;
  margin-left: -1vw;
  /* width: 22.73vw !important;
  height: 15vh !important;
background: #FFFFFF;
box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
border-radius: 16px; */
}
.hamburger-menu-container{
   display: none;
}
@media only screen  and (min-width: 1441px){
 
  .sideBar-container {
    width: 19.44vw;
    height: 100vh;
    position: fixed;
    /* opacity: 0.2; */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 4px 0px 15px rgba(0, 0, 0, 0.15);
  }
  .logo-png {
    margin-top: 3.77vh;
    margin-left: 2.25vw;
  }
  .organization-name {
    margin-top: 0.97vh;
    margin-left: 2.36vw;
    width: 11.18vw;
    height: 1.70vh;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 300;
    font-size: 0.83vw;
    line-height: 1.70vh;
    color: #333333;
  }
  .sideBar-options-contianer {
    display: flex;
    flex-direction: column;
    /* gap: 0.65vh; */
    margin-top: 6.82vh;
    /* margin-left: 69px; */
  
  }
  .sideBar-options-contianer 
  a {
    color: #d9d9d9;
    /* margin-left: -5px; */
    margin-left: 2.36vw;
    width: 13.88vw;
    /* min-width: 152px; */
   /* height: 6.63vh; */
    padding: 16px;
    /* padding-top: 1.94vh; */
      /* padding-left: 2.29vw; */
   
    /* font-size: 1.25vw;
    line-height: 2.55vh; */
    color: #333333;
  }
  .sideBar-active-option{
    /* align-items: center; */
  /* width: 200px;
  height: 52px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px; */
  font-weight: 400 !important;
  color: #FFFFFF !important;
  background: linear-gradient(90deg, #683FDB 0%, #A05AD8 100%);
  /* border-radius: 12px; */
  
  }
  a:active {
    color: #ffffff;
  }
  a:hover{
    color: #333333;
  }
  /* -----Main-Container------- */
  /* .dashboard-main-container {
    margin-left: 286px;
  } */
  
  .search-bar {
    margin-top: 2.92vh;
    /* margin-left: 48px; */
    width: 48.33vw;
    height: 4.87vh;
    background: rgba(214, 214, 214, 0.2);
    outline: none;
    border-radius: 32px;
    border-style: none;
    text-indent: 25px;
    padding: 8px;
  }
  .search-bar::placeholder {
    /* padding-left: 18px; */
    /* padding-top: 8px !important; */
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 0.97vw;
    line-height: 2.07vh;
    color: #000000;
    opacity: 0.8;
  }
  .client-name {
    /* width: 122px; */
    /* height: 19px; */
    white-space: nowrap;
    /* margin-top: 35px; */
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 1.11vw;
    line-height: 2.31vh;
    color: #333333;
    cursor: pointer;
  }
  .profile-img {
    width: 2.22vw;
    height: 3.89vh;
    /* background: #d9d9d9; */
    border-radius: 50%;
  }
  /* -----------------------Dashboard-main-container-------------------------------- */

 
  .active-lead-text {
    width:9.44vw;
    height: 3.41vh;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 1.66vw;
    line-height: 3.41vh;
    color: #333333;
  }
  .view-all-text {
    margin-top: 0.97vh;
    width: 10.76vw;
    height: 1.82vh;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 0.97vw;
    line-height: 1.82vh;
    color: #2775eb;
    cursor: pointer;
    padding-left: 6.25vw;
  }
  
  .abc-text {
    width: 3.40vw;
    height: 3.41vh;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 1.66vw;
    line-height: 3.41vh;
    white-space: nowrap;
    color: #333333;
  }
  .active-leads-content {
    /* scroll-behavior: auto; */
    /* margin-left: 48px; */
    /* margin-top: 16px; */
    /* overflow: hidden; */
    /* width: 49.88vw; */
    /* width: 47.61vw;
    height: 34.71vh;
    background: #ffffff;
    box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
    border-radius: 16px; */
  }
  .graph-container{
    /* text-align: center;
    align-items: center; */
    /* overflow: scroll; */
    /* margin-top: 1.94vh; */
  /* width: 22.99vw;
  height: 34.71vh; */
  background: #FFFFFF;
  box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
  border-radius: 16px;
  }
  .active-inactive-text{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-size: 0.83vw;
    line-height: 2.67vh;    
    color: #000000;
    opacity: 0.8;
  }
  .total-clients-text{
    margin: auto;
      margin-top: -24.36vh;
    width:3.47vw;
  height: 8.03vh;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-size: 1.11vw;
  line-height: 2.67vh;
  color: #000000;
  opacity: 0.8;
  }
  span{
  
    font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 1.11vw;
  line-height: 2.67vh;
  color: #000000;
  }
  .offer-text{
  /* width: 68px;
  height: 28px; */
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 3.41vh;
  color: #333333;
  white-space: nowrap;
  
  }
  .my-site-text{
  width: 5.48vw;
  height: 3.41vh;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 3.41vh;
  color: #333333;
  }
  


}
  
@media only screen  and (max-width: 767px){
  .sideBar-container{
      display: none;
  }
  .sideBar-options-contianer a{
    width: 43.23vw;
    height: 12.2vw;
    font-size: 16px;
    line-height: 19px;
    gap: 3.07vw;
    /* padding-top: 0; */
    padding-left: 4.29vw;
    /* height: 5.8vh; */
    /* border-radius: 3.07vw; */
  }
  .sideBar-options-contianer a:hover{
    background: rgb(104,63,219 ,0.05);
  /* opacity: 0.05; */
  border-radius: 3.07vw;
  /* color: #cb2828 !important; */
  }
  .sideBar-options-contianer > a > svg{
    width: 7.17vw;
    height: 7.17vw;
  }
 .sideBar-active-option{
   border-radius: 3.07vw;
   font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
   font-size: 16px;
   
   line-height: 19px;
 }
 .sideBar-options-contianer .sideBar-active-option:hover{
  border-radius: 3.07vw;
 }
 /* .sideBar-options-contianer a:hover{
  background-color: #000000;
 } */
 .sideBar-active-option > svg{
  width: 7.17vw;
  height: 7.17vw;

 }
 .logo-png{
  margin-top: 0;
  width: 70% !important;
 }
 .organization-name{
  font-size: 3.2vw;
  line-height: 4vw;
  width: 55vw;
 }
 .profile-container >.client-name{
    display: none;
  }
 

   /* ---------------------Dashboard-main-container-------------------- */
  .dashboard-main-container{
    /* display: none; */
    padding: 0 6.15vw 6.15vw 6.15vw;
  }
  .hamburger-menu-container{
    display: flex;
    /* margin-top: 2.22vw; */
  }
  .serach-profile-container{
    display: flex;
    justify-content: space-evenly;
    /* width: 0; */
    width: 80%;
    margin-top: 3.33vw;
    margin-left: 2vw;
 }
  .search-bar{
     margin-top: 0;
     width: 80%;
     /* width: 110.38vw; */
     height: 9.5vw;
     /* padding: 7vw; */
     padding-left: 0;
  }
  .search-bar::placeholder{
    font-size: 3.8vw;
    line-height: 4vw;
    /* height: 9.5vw; */
  }
  .profile-container{
     /* display: none; */
     margin-top: 0;
  }
  .profile-image-container{
    margin-right:-11.94vw;
    padding-left:9px;
    align-items: center;
    gap: 3.33vw;
  }
  .profile-image-container >svg{
    display: flex;
    cursor: pointer;
  }
  .profile-img{
    width: 10.22vw;
    height: 10.22vw;
  }
  .icon-cont-inside-profile{
    display: none;
  }

  .dashborad-top-card-container{
    /* display: none; */
    flex-direction: column;
    margin: 8.17vw 0 0 0;
    /* padding: 0 6.15vw 0 6.15vw; */
    gap: 8.20vw;
  }
  .activeleads-upcomingevents-container{
    /* display: none; */
    flex-direction: column;
    margin: 12.30vw 0 0 0;
    /* padding: 0 6.15vw 0 6.15vw; */
  }
  .active-lead-text{
    width: unset;
    height: unset;
    font-size: 18px;
   line-height: 18px;
   margin: unset;
   /* opacity: 0.8; */
  }
  .view-all-text{
    width: unset;
    height: unset;
    font-size: 12px;
    line-height: 12px;  
  }
  .active-leads-content{
    margin-top: 4.30vw;
    width: 87.69vw;
    height: 75.89vw;
  }
  .upcoming-events-container{
    /* display: none; */
    margin-top: 11.79vw;
  }
  .abc-text{
    width: unset;
    height: unset;
    font-size: 18px;
    line-height: 18px;
    margin: unset;
  }
  .graph-container{
    width: 87.69vw;
    height: 75.89vw;
    margin-top: 4.10vw;
  }
  .dashborad-bottom-card-container{
    /* display: none; */
    flex-direction: column;
    margin: 12.30vw 0 0 0;
    gap: 12.30vw;
  }
  .offer-news-cont{
    flex-direction: column;
    gap: 12.30vw;
  }
  .offer-text{
    font-size: 18px;
    line-height: 18px;
    margin: unset;
  }
  .managerdetail-main-container{
    
  }
  .my-site-text{
    width: unset;
    height: unset;
    font-size: 18px;
    line-height: 18px;
    margin: unset;
  }
  .rm-profile-detail-cont{
     gap:4.10vw
  }
  .profile-img-container-rm{
    width: 17.69vw;
    height: 17.69vw;
  }
  .manager-name{
    font-size: 16px;
    line-height: 24px;
  }
  .manager-type{
    font-size: 12px;
    line-height: 16px;
  }
  .contact-text{
    font-size: 14px;
     line-height: 21px;
  }
  .manager-conatct-data{
    font-size: 14px;
    line-height: 21px;
  }

  /* login notificaton toaster */

  .ant-message-custom-content > span{
    font-size: 14px;
    line-height: 16px;
  }
}