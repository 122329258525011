.mrktg-img-shr-modal {
  display: flex;
  justify-content: center;
  padding: unset;
  width: 47.7vw !important;
  /* top :10px */
}
.mrktg-img-shr-modal .ant-modal-content {
  /* width: 47.7vw; */
  /* height: 47.9vw; */
  width: 43.7vw;
  height: 25.9vh;
  border-radius: 1.11vw;
  /* padding: 5.13vw 1.66vw  0 1.66vw; */
}
.mrktg-img-shr-modal .ant-modal-body {
  padding: unset;
  height: 43.84vw;
  padding-top: unset;
}
.mrktg-img-shr-modal .ant-modal-footer {
  display: none;
  /* padding: unset;
    background: linear-gradient(90deg, #683FDB 0%, #A05AD8 100%);
    height: 5.41vw;
    bor */
}
.mrktg-img-shr-modal .ant-modal-footer button {
  display: none;
}
.mrktg-img-shr-modal .ant-modal-close {
  top: -0.34vw;
  right: -0.41vw;
  background: #683fdb;
  border-radius: 50%;
}
.mrktg-img-shr-modal .ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.95vw;
  height: 1.94vw;
}
.mrktg-img-shr-modal .ant-modal-close-x svg {
  /* display: none; */
  font-family: "Rubik";
  font-style: normal;
  font-size: 1.11vw;
  line-height: 1.38vw;
  color: #ffffff;
}
/* .mrktg-img-shr-modal canvas{
    width: 100%;
    background-color: blue;
    border: 4px solid black;
 } */
.new-my-img-div {
  background: #f1f1f1;
  border-radius: 1.11vw;
}

.my-img-div {
  display: flex;
  flex-direction: column;
  /* width: 45.47vw;
  height: 31.87vw; */
  width: 42.3vw;
  height: 45.9vh;
  padding: 1.5vw 0 0 1.5vw;
  border-radius: 0.55vw;
}
.my-img-div > img {
  /* margin-left: 14px; */
  border-radius: 0.55vw;
}

.info-div-cont {
  padding-left: 1.5vw;
  margin-top: 1.11vw;
}
.info-div-cont .info-head {
  display: flex;
  gap: 0.3vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.66vw;
  color: #000000;
  opacity: 0.8;
}
.info-div-cont .info-sbhd {
  margin: unset;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 1.11vw;
  line-height: 1.66vw;
  color: #000000;
  opacity: 0.8;
}
.footer-div-cont {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 1.11vw;
  background: linear-gradient(90deg, #683fdb 0%, #a05ad8 100%);
  /* height: 5.41vw; */
  border-radius: 0 0 1.11vw 1.11vw;
  padding: 1.25vw 1.66vw 1.66vw 1.66vw;
  /* background-color: #000000; */
}
.shr-dwld-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.11vw;
  gap: 1.38vw;
  /* background: #000000; */
}
.shr-dwld-btn > svg {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .mrktg-img-shr-modal{
    width: unset !important;
  }
  .mrktg-img-shr-modal .ant-modal-close-x {
    /* width: 7.79vw;
    height: 7.79vw; */
    width: 5.79vw;
    height: 5.79vw;
  }
  .mrktg-img-shr-modal .ant-modal-close{
    top: -2.34vw;
    right: -2.41vw;
  }
  .mrktg-img-shr-modal .ant-modal-close-x svg{
    font-size: 16px;
    line-height: 20px;
  }

  .mrktg-img-shr-modal .ant-modal-content{
    width: 88.87vw;
  }
  .my-img-div{
    width: 86.47vw;
    height: 59.9vw;
    padding: 2.8vw 0 0 2.5vw
  }
  .info-div-cont {
    padding-left: 2.5vw;
  }
  .info-div-cont .info-head {
    font-size: 16px;
    line-height: 24px;
  }
  .info-div-cont .info-sbhd{
    font-size: 16px;
    line-height: 24px;
  }
}
