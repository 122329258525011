* {
  margin: 0 ;
  padding: 0 ;
  box-sizing: border-box;
}
.disable-hover{
  cursor: unset!important;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500&family=Raleway:wght@100;700&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;700&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
/* .App {
  text-align: center;
} */
/* #root {
  height: 100vh;
} */
/* p{
  margin: 13px !important;
  white-space: nowrap;
  /* padding: 0 !important; */
