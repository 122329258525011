.client-details-main-container {
  padding-left: 3.33vw;
  margin-top: 2.22vw;
  padding-right: 3.33vw;
}

.clients-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.client-name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  /* text-decoration-line: underline; */
  color: #000000;
  opacity: 0.8;
  /* margin-top: 0.50vh;   / */
}

.client-detail-heading {
  /* position: absolute; */
  margin-top: 1.22vw;
  /* width: 146px;
height: 28px; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  white-space: nowrap;
  margin-bottom: 0;
}

.client-details-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 36.72vw;
  height: 10.62vw;
  margin-top: 2.22vw;
}

.add-event-btn {
  margin-top: 2.89vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #2775eb;
  cursor: pointer;
  white-space: nowrap;
}

/* -----------AboutClient--CSS---------- */
.client-details-and-upcoming-container {
  display: flex;
  justify-content: space-between;
}
.clientInfoHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  opacity: 0.8;
  white-space: nowrap;
  margin-bottom: 0.27vw;
}

.clientInfoHeadingData {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.6;
  /* white-space: nowrap; */
  margin-bottom: unset;
}
.details-heading-info-container {
  display: flex;
  flex-direction: column;
  gap: 2.22vw;
}
/* ------------------------ADD event -model -css -------------- */
.add-btn-spcl-text-cont {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1.32vw;
}
.add-btn-spcl-text-cont > h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  margin-bottom: 0;
}
.add-btn-spcl-text-cont > p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.04vw;
  color: #2775eb;
  margin-bottom: 0;
  cursor: pointer;
}
/* .ant-modal-wrap .add-event-modal div {
  width: 47.70vw;
  border-radius: 1.11vw;
  /* background-color: #2775eb; */
/* } */

.add-event-main-container {
  display: flex;
  flex-direction: column;
  gap: 2.22vw;
  /* width: 7vw; */
  /* padding-left: 2.80vw; */
  /* padding-top: -2vw; */
}

.add-event-modal .ant-modal-content {
  width: 47.7vw !important;
  border-radius: 1.11vw !important;
  height: auto;
}
.add-event-modal .ant-modal-content {
  /* height: 66.37vh !important; */
  /* background-color: #2775eb; */
}

.add-event-modal .ant-modal-header {
  font-family: "Rubik" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  color: #333333 !important;
  /* padding-top: 3.47vw !important;
  padding-left: 4.44vw !important; */
  padding: 3.47vw 0 0 4.44vw;
  border-bottom: none;
  border-radius: 1.11vw;
}

.add-event-modal .ant-modal-title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
}
.add-event-modal .ant-modal-body {
  padding: 0 0 0 4.44vw;
  margin-top: 2.22vw;
}
/* .add-event-modal .ant-modal-footer{

} */
.hide-name-input-cont {
  display: none;
}
.name-input-cont .add-event-input {
  display: flex;
  position: relative;
  /* margin-bottom: 2.22vw; */
  border-style: none;
  outline: none;
  min-width: 210px;
  width: 23.33vw;
  border-bottom: 1px solid rgba(208, 208, 208, 0.5) !important;
  padding-bottom: 8px;
}
.date-input-cont .add-event-input {
  position: relative;
  margin-bottom: 2.22vw;
  border-style: none;
  outline: none;
  min-width: 210px;
  width: 23.33vw;
  border-bottom: 1px solid rgba(208, 208, 208, 0.5) !important;
  padding-bottom: 8px;
}

.date-input-cont .ant-picker {
  padding: 0.33vw;
}

.date-input-cont .add-event-input input {
  font-size: 1.11vw;
}

.ant-picker-date-panel {
  width: unset;
}

.ant-picker-date-panel .ant-picker-content {
  width: 21.69vw !important;
}

.ant-picker-focused,
.ant-picker:hover {
  border-color: unset;
  border-radius: unset;
  outline: none;
  box-shadow: unset;
}

.ant-picker-input > input:hover {
  outline: none;
}

.ant-picker-input > input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  /* ye hai csss placeholder ke liye */
  font-size: 1.11vw;
  line-height: 1.25vw;
  color: #000000;
  opacity: 0.1;
}

/* .add-event-input::-webkit-calendar-picker-indicator {
  box-sizing: border-box;
  position: absolute;
  padding-left: 22.33vw;
  height: 110%;
  /* padding-top: 1.5vw; */
/* width: 100%;
     padding-left: auto; */
/* padding-right: 200px; */

/* border: 1px dashed red; */

/* input[type="date"]::before { 
  content: attr(data-placeholder);
  width: 100%;
} */

/* hide our custom/fake placeholder text when in focus to show the default
 * 'mm/dd/yyyy' value and when valid to show the users' date of birth value.
 */
/* input[type="date"]:focus::before,
input[type="date"]:valid::before { display: none } */

.event-selecter {
  padding-bottom: 8px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom: 1px solid #d0d0d0;
  outline: none;
  width: 23.33vw;
  min-width: 210px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.25vw;
  color: #000000;
  opacity: 0.8;
}

.add-event-modal .ant-modal-footer {
  text-align: left;
  margin: 0 0 0 3.44vw;

  border-top: none;

  /* gap: 57px; */
  /* border-style: none; */
}

.add-event-modal .ant-modal-footer .save-btn {
  border-style: none;
  width: 171px;
  height: 48px;
  background: #ff9900;
  border-radius: 8px;
  /* color: #2775EB; */
  color: #ffffff !important;
  margin-bottom: 10px;
}

.add-event-modal .ant-modal-footer .save-btn span {
  color: #ffffff;
}

.add-event-modal .ant-modal-footer .cancel-btn {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border-style: none;
  margin-left: 1vw;
}

.add-event-modal .ant-modal-footer .cancel-btn span {
  color: #2775eb;
  box-shadow: unset;
}

/* --------------------------Event-----Share-----------modal ------------csss--------- */
.event-Share-modal .ant-modal-header {
  border-radius: 16px;
  border-bottom: none;
}

.ant-modal-wrap .event-Share-modal div {
  /* height: 57.61vh; */
}

.event-Share-modal .ant-modal-content {
  width: 58.4vw;
  height: 32.84vw;

  border-radius: 16px;
}

.event-Share-modal .ant-modal-title {
  font-family: "Rubik" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  color: #333333 !important;
  padding-top: 50px !important;
  padding-left: 40px !important;
}

.via-sms-contanier {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  border: 2px solid #683fdb;
  border-radius: 50%;
  cursor: pointer;
}

.via-text {
  margin: 10px 0 0 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  opacity: 0.6;
}

.copy-url-container {
  margin-top: 3.33vw;
}

.copy-url-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  opacity: 0.6;
}

.url-input {
  box-sizing: border-box;
  width: 38.95vw;
  height: 6.57vh;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding-left: 20px;
  outline: none;
}

.event-Share-modal .ant-modal-footer {
  border: none;
  text-align: left;
  margin-left: 45px;
  display: none;
}

.event-Share-modal .copy-btn {
  /* outline: none; */
  border: none;
  width: 171px;
  height: 48px;
  background: #ff9900;
  border-radius: 8px;
  margin-bottom: 20px;
}

.copy-btn span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

/* ------------Event--------Carousel----------------------- */
.carousel-container .slick-list {
  /* height: 15vw !important; */
  width: 24.9vw !important;
}
.icon-event-data-container {
  display: flex;
  gap: 0.9vw;
  /* textAlign: baseline; */
}
.event-icon {
  display: flex;
  padding-top: 0.5vw;
  /* align-self: center; */
}
.evnt-date-type-contianer {
  display: flex;
  gap: 0.69vw;
  margin-top: 0.13vw;
}

holder-name-date-container > .name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.18vw;
  letter-spacing: 0.1px;
  color: #333333;
  opacity: 0.8;
}
.evnt-date-type-contianer > .date {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 0.69vw;
  line-height: 0.97vw;
  color: #666666;
}
.carousel-container,
.event-carousel {
  /* display: flex; */
  /* border: 2px solid red;  */
  /* height: 1vw; */
  /* width: 24vw; */
}

.ant-carousel .slick-dots {
  margin-left: 3% !important;
}

.carousel-container .ant-carousel .slick-dots-bottom {
  margin-bottom: -35px !important;
}

.carousel-container .slick-dots li button:before {
  font-size: 0.83vw;
}

/* -----------upcomming-transaction-----contanier----csss---- */
.upcomming-transactions-container {
  width: 24.7vw;
  /* margin-right: 7.736vw; */
  /* padding-right: 1vw; */

  /* margin-right: 4.736vw;
  width: 22vw; */
}

.upcomming-transactions {
  margin-top: 0.9vw;
  /* background: red; */
  height: 15vw;
  /* overflow-y: scroll; */
  overflow-x: hidden;
}

.upcomming-transactions::-webkit-scrollbar {
  width: 0.27vw;
  /* outline: 1px solid slategrey; */
}

.upcomming-transactions::-webkit-scrollbar-track-piece {
  background: #dadada;
  opacity: 0.4;
  border-radius: 2.08vw;
}

.upcomming-transactions::-webkit-scrollbar-thumb {
  /* height: 3px; */
  background: #683fdb;
  border-radius: 2.08vw;
}

.upcomming-transactions-container .first-para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.18vw;
  color: #000000;
  opacity: 0.6;
  margin-bottom: 2.22vw;
  width: 22.5vw;
}

.upcomming-transactions-container .second-para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.18vw;
  color: #000000;
  opacity: 0.6;
  margin-top: 2.22vw;
  margin-bottom: 0;
  width: 21.11vw;
}

.all-transaction-btn-text {
  /* margin-top: 2.82vw; */
  align-self: flex-end;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #2775eb;
  cursor: pointer;
  white-space: nowrap;
}

.upcomming-transactions-container p > span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16.69px;
  color: #000000;
}

/* -------------------------investment-portfo-cont--css-------- */
.investment-portf-cont {
  margin-top: 1vw;
}
.investment-portFo-empty-Scrn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.investment-portFo-empty-Scrn > svg {
  margin-top: 2.22vw;
  /* width: 20vw;
 height: auto; */
}
.investment-portFo-empty-Scrn > h1 {
  margin-bottom: 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1.52vw;
  line-height: 1.66vw;
  color: #333333;
  opacity: 0.8;
  margin-top: 2.22vw;
}
.investment-portFo-empty-Scrn > p {
  margin-top: 1vw;
  margin-bottom: 0;
  /* width: 23.25vw; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.25vw;
  color: #999999;
}
.investment-portfo-heading {
  display: flex;
  justify-content: space-between;
}

.investment-portfo-heading > h1 {
  margin-bottom: 1.11vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  white-space: nowrap;
}

.downloadtxt-png-cont {
  display: flex;
  justify-content: baseline;
  align-items: center;
  gap: 0.7vw;
}

.downloadtxt-png-cont > img {
  width: 1.24vw;
  height: 1.46vw;
  padding-top: 2px;
  /* margin-top: 1vw; */
}

.investment-portfo-heading h6 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.41vw;
  /* color: #2775EB; */
  margin: 0;
  /* cursor: pointer; */
  white-space: nowrap;
}

.invested-portFolio-table-cont {
  /* margin-top: 1.11vw; */
  height: 22vw;
  overflow: auto;
  /* display: flex; */
  justify-content: center;
}
.invested-portFolio-table-cont .table-style-one tr:hover td{
   background: unset;
}
.invested-portFolio-table-cont .table-style-one{

}
.invested-portFolio-table-cont::-webkit-scrollbar {
  width: 3px;
  height: 3px;

}

.invested-portFolio-table-cont ::-webkit-scrollbar-track-piece {
  background: #DADADA;
  opacity: 0.4;
  border-radius: 2.08vw;
}

::-webkit-scrollbar-thumb {
  background: #683FDB;
  border-radius: 2.08vw;
}
.inv_port_fundname {
  cursor: pointer;
  white-space: nowrap;
}

.investment-portfolio-modal-cont {
  background-color: #fff;
  border-radius: 1.11vw !important;
  padding: 0;
  width: 82.22vw !important;
  height: 34.86vw;
  top: 6.944vw;
}

.investment-portfolio-modal-cont .ant-modal-body {
  padding: 2.22vw 0 0 0;
}

.investment-portfolio-modal-cont .ant-modal-content {
  border-radius: 1.11vw !important;
  height: 100%;
}

.investment-portfolio-modal-cont .ant-modal-header {
  border-radius: 1.11vw !important;
  border-bottom: none;
  padding: 0;
}

.investment-portfolio-modal-cont svg {
  /* font-family: 'Rubik'; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 133% */

  color: #666666;
}

.investment-portfolio-modal-cont .ant-modal-title {
  padding: 4.583vw 0 0 4.444vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.666vw;
  line-height: 1.94vw;
}

.investment-portfolio-modal-cont .invested-portFolio-table-cont {
  margin: 0 auto;
  width: 73.333vw;
  height: 21.66vw;
  left: 4.444vw;
  top: 8.75vw;
  /* display: block; */
  background: #ffffff;
  box-shadow: 0px 0.277vw 1.24vw rgba(116, 82, 214, 0.15);
  border-radius: 1.11vw;
}

/* ------------Chart----CSS----------- */
.chart {
  width: 73.44vw;
  margin-top: 5.12vw;
}

/* -------Pie-Chert----- */
.t-3-sector {
  height: 11.86vw;
  font-family: "Rubik";
  font-style: normal;
  font-size: 85.11vw;
  font-size: 1.11vw;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #000000;
  opacity: 0.8;
  margin-top: -195px;
  margin-left: -2vw;
  gap: 0.4vw;
}

.level {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  opacity: 0.8;
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .t-3-sector {
    margin-top: -13vw;
    gap: 0.2vw;
  }
}

/* ------------Portfolio--and-----Diversification---------- */
.portfolio-summry-container {
  margin-top: -0.11vw;
}
.portfolio-diversi-heading {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  color: #333333;
  margin-bottom: unset;
}

.portfolio-sumry-data-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 2.22vw;
  width: 68.61vw;
  height: 123px;
  /* height: 10.62vw; */
  margin-top: 2.22vw;
  row-gap: 1.22vw;
}

.portfolio-sumry-data-container > div {
  /* width: 33%; */
}

/* ------------transaction------css-------- */
.client-detl-pg-trnstn-cont .table-style-one tr:hover td{
  background: unset;
}

.transaction-container {
  margin-top: 3.33vw;
}
.client-detail-transaction-empty-scrn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.client-detail-transaction-empty-scrn > svg {
  margin-top: 2.22vw;
}
.client-detail-transaction-empty-scrn > h1 {
  margin-bottom: 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1.52vw;
  line-height: 1.66vw;
  color: #333333;
  opacity: 0.8;
  margin-top: 2.22vw;
}
.client-detail-transaction-empty-scrn > p {
  margin-top: 1vw;

  margin-bottom: 0;
  /* width: 23.25vw; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.25vw;
  color: #999999;
  margin-bottom: 1.11vw;
}
.download-icon-cont {
  display: flex;
  justify-content: space-between;
}
.downloadtxt-png-cont > h6 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.41vw;
  /* color: #2775EB; */
  margin: 0;
  /* cursor: pointer; */
  white-space: nowrap;
}
.transaction-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin-bottom: 0;
}

.transaction-table {
  margin-top: 2.22vw;
  width: 73.53vw;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
  border-radius: 16px;
}

/* -------Recommendation------CSS------- */
.recommendation-container {
  /* padding-bottom: 40px; */
}
.rcmdfund-single-data-outer-container {
  width: 29.58vw;
}
.rcmndfund-single-data-inner-container {
  display: flex;
  justify-content: space-between;
  /* gap: 3.75vw; */
}
.logo-fundName-container {
  display: flex;
  gap: 1.11vw;
}
.rcmndfund-logo-cont {
  width: 2.83vw;
  height: 2.83vw;
}
.rcmndfund-sharelogo-precnt-container {
  display: flex;
  gap: 1vw;
  align-items: baseline;
  /* margin-left: 1.89vw; */
}
.recommendation-heading {
  margin-top: 3.33vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
}

.recmnd-data-container {
  display: flex;
  flex-wrap: wrap;
  width: 70vw;
  /* column-gap: 15vw; */
  justify-content: space-between;
}

.remnd-heading {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.25vw;
  color: #333333;
  opacity: 0.8;
  margin: 0;
}

.remnd-subheading {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 1.25vw;
  color: #000000;
  opacity: 0.4;
  margin: 0;
}

.percentage-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.25vw;
  color: #3ea400;
}
.negative-percentage-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.25vw;
  color: red;
}

.horizntl-line {
  width: 30.58vw;
  opacity: 0.2;
  border: 1px solid #000000;
  margin-top: 27px;
  margin-bottom: 27px;
}

ul,
li {
  margin-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.45vw;
  color: #000000;

  opacity: 0.8;
}

/* -------------------Wealth----Calculator-----css------ */
.cal-togle-btn-cont{
  display: flex;
  margin-top: 2.22vw;
}
.cal-togle-btn-cont > .toggle-btn{
  width: 10.83vw;

  text-align: center;
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 1.38vw;
line-height: 2.08vw;
letter-spacing: 0.1px;
color: #000000;
opacity: 0.8;
cursor: pointer;

}
.cal-togle-btn-cont > .toggle-btn-actv{
  width: 10.83vw;
  height: 3.11vw;
  text-align: center;
font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 1.38vw;
line-height: 2.08vw;
letter-spacing: 0.1px;
color: #683FDB;
cursor: pointer;
border-bottom: 0.20vw solid #683FDB;;
}

.wealth-calculator-container {
  /* display: flex;
  justify-content: space-between; */

  margin-top: 2.52vw;
  align-items: baseline;
  /* width: 72vw; */
}
.wealth-calculator {
  /* display: flex; */
  padding-bottom: 48px;
  /* width: 72vw; */
}

/* .wealth-calculator > p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.6;
} */
.wealth-calculator span {
  font-weight: 400;
  font-size: 16px;
  /* opacity: 0.6; */
}

.wealth-calculator input {
  padding-left: 8px;
  width: 120px;
  border-top: none;
  border-left: none;
  border-right: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  opacity: 0.8;
  outline: none;
  padding-bottom: 4px;
  border-bottom: 1px solid #d0d0d0;
  margin-top: -3px;
}

.wealth-subheading {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.6;
  margin: 0;
}
.tenative-period-heading-contianer {
  margin-top: 2.84vw;
}

.slider-container {
  margin-top: 1.66vw;
}
.investment-return-value-container {
  display: flex;
  margin-top: 5.36vw;
  gap: 6.45vw;
}
.wealth_cal-in-public-share .ant-slider-rail {
  width: 45.7vw;
}
.ant-slider-rail {
  width: 35.7vw;
  height: 8px;
  background: #ebebeb;
}

.ant-slider-handle {
  background: #683fdb;
  border: #683fdb;
  margin-top: -8px;
  width: 24px;
  height: 24px;
  border: 2px solid white;
}

.ant-slider-dot {
  background: #ebebeb;
  margin-top: -6px;
  width: 24px;
  height: 24px;
  border: 2px solid white;
}

.ant-slider-track {
  height: 8px;
  background: #683fdb;
}

.ant-slider:hover .ant-slider-track {
  background: #683fdb;
  height: 8px;
  /* border: 2px solid white; */
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  background: #683fdb;
  border: #683fdb;
  border: 2px solid white;
}

.ant-slider-dot-active {
  background: #683fdb !important;
  border: #683fdb !important;
  margin-top: -6px !important;
  width: 24px !important;
  height: 24px !important;
  border: 2px solid white !important;
}
.wealth-calculator .ant-slider-mark{
  /* top: -0.97vw; */

}
.ant-slider-mark-text {
  margin-top: 1vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.8;
}
.hdng_intrate_inpt_container{
  display: flex;
  justify-content: space-between;
  width: 36.8vw;

  margin-top: 4.6vw;
  align-items: baseline;
}
.hdng_intrate_inpt_container_hide{
   display: none;
}
.hdng_intrate_inpt_container>p{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.18vw;  
  color: #000000;
  opacity: 0.6;
  margin: unset;
}
.hdng_intrate_inpt_container >.prctg-icn{
font-family: 'Rubik';
font-style: normal;
font-weight: 600;
font-size: 1.11vw;
line-height: 1.66vw;
color: #000000;
opacity: 0.8;
border-bottom: 0.069vw solid #D0D0D0;
padding: 0  1vw 0 1vw;
}
.hdng_intrate_inpt_container input{
  border-style:hidden !important;
  max-width: 3vw;
  /* max-width: 148px; */
}
.rtn-slider-container{
  margin-top: 0.99vw;
  width: 37vw;
  

}
.rtn-slider-container-hide{
  display:none
}
.rtn-slider-container .ant-slider{
  margin: unset;
}
.rtn-slider-container .ant-slider-rail{
  width: 37vw;
  border-radius: 2.22vw;
}
.rtn-slider-container .ant-slider-track{
  border-radius: 2.22vw;
}
.rtn-slider-container .ant-slider-handle{
 border: 0.13vw solid white;
}
.total-invsmt-text {
  margin: 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  opacity: 0.6;
}

.rupee {
  margin: 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  opacity: 0.6;
}

/* -------pagination ----css----- */

.pageNumbers {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: 50px;
  opacity: 1;
}

.pageNumbers li {
  /* text-align: center;
  text-justify: center; */
  font-family: Rubik;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  /* border: 1px solid black; */
  cursor: pointer;
  margin: 10px;
  font-size: 13px;
  font-weight: bold;
  opacity: 1;
}

.pageNumbers li.active {
  background-color: #683fdb;
  color: #fff;
  opacity: 1;
}

.btn_prev_next {
  /* text-align: center;
  text-justify: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 30px;
  color: #683fdb;
  /* padding-left: 2px; */
  border-radius: 5px;
  border: 1px solid #683fdb;
  cursor: pointer;
  margin: 8px;
  font-size: 13px;
  font-weight: bold;
}

.pageNumbers > button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

@media only screen and (min-width: 1441px) {
  .clients-text {
    font-size: 0.97vw;
    line-height: 1.25vw;
  }

  .client-name {
    font-size: 0.97vw;
    line-height: 1.25vw;
  }

  .client-detail-heading {
    /* margin-top: 2.22vw; */

    font-size: 1.66vw;
    line-height: 1.94vw;
  }

  .add-event-btn {
    margin-top: 2.89vw;

    font-size: 0.97vw;
    line-height: 1.04vw;
  }

  /* -----------AboutClient--CSS---------- */
  .clientInfoHeading {
    font-size: 0.83vw;
    line-height: 1.09vw;
  }

  .clientInfoHeadingData {
    font-size: 0.97vw;
    line-height: 1, 18vw;
  }

  /* ---------------Add---Event------modal---------Css----------------- */

  /* .ant-modal-wrap .add-event-modal div {
    width: 47.70vw;
    border-radius: 1.11vw; */
  /* }  */

  .add-event-modal .ant-modal-content {
    /* height: 66.37vh !important; */
    /* background-color: #2775eb; */
  }

  .add-event-modal .ant-modal-header {
    font-size: 1.66vw !important;
    line-height: 1.94vw !important;

    /* padding-top: 50px !important;
    padding-left: 64px !important; */
  }

  .add-event-modal .ant-modal-title {
    font-size: 1.66vw;
    line-height: 1.94vw;
  }

  .add-event-modal .ant-modal-footer {
    text-align: left;
    /* margin-left: 45px !important; */

    border-top: none;

    /* gap: 57px; */
    /* border-style: none; */
  }

  .add-event-modal .ant-modal-footer .save-btn {
    border-style: none;
    width: 11.87vw;
    height: 3.33vw;
    background: #ff9900;
    border-radius: 0.55vw;
    /* color: #2775EB; */
    margin-bottom: 1vw;
    color: #ffffff !important;
  }

  .add-event-modal .ant-modal-footer .save-btn span {
  }

  .add-event-modal .ant-modal-footer .cancel-btn {
    font-size: 1.11vw;
    line-height: 1.041vw;
    border-style: none;
  }

  .add-event-modal .ant-modal-footer .cancel-btn span {
  }

  /* ------------upcomming-transaction------css-- */
  .upcomming-transactions-container p {
    font-size: 0.97vw;
    line-height: 1.18vw;

    /* margin-bottom: 1.66vw; */
  }

  .all-transaction-btn-text {
    /* margin-top: 2.82vw; */

    font-size: 0.97vw;
    line-height: 1.04vw;
  }

  .upcomming-transactions-container p > span {
    font-size: 0.97vw;
    line-height: 1.15vw;
  }

  /* ------------Chart----CSS----------- */
  .chart {
    width: 61.85vw;
    margin-top: 5.12vw;
  }

  /* -------Pie-Chert----- */
  .t-3-sector {
    margin-top: -12.13vw;
    margin-left: -2vw;
    gap: 0.4vw;
  }

  .level {
    font-size: 0.88vw;
    line-height: 1.52vw;
  }

  /* ------------Portfolio--and-----Diversification---------- */
  .portfolio-diversi-heading {
    font-size: 1.66vw;
    line-height: 1.94vw;
  }

  .portfolio-sumry-data-container {
    height: 7.62vw;
  }

  /* ------------transaction------css-------- */

  .transaction-text {
    font-size: 1.66vw;
    line-height: 1.94vw;
  }

  .transaction-table {
    margin-top: 2.22vw;
    width: 73.53vw;

    border-radius: 1.1w1v;
  }

  /* -------Recommendation------CSS------- */
  .recommendation-container {
    /* padding-bottom: 2.77vw; */
  }

  .recommendation-heading {
    margin-top: 3.33vw;

    font-size: 1.66vw;
    line-height: 1.94vw;
  }

  /* .recmnd-data-container {
 
    width: 70vw;
  
  } */
  /* .remnd-heading {
  
    font-size: 1.11vw;
    line-height: 1.25vw;
   
  }
  .remnd-subheading {
  
    font-size: 0.83vw;
    line-height: 1.25vw;

  } */
  .percentage-text {
    font-size: 1.11vw;
    line-height: 1.25vw;
  }
  .negative-percentage-text {
    font-size: 1.11vw;
    line-height: 1.25vw;
  }
  .horizntl-line {
    width: 30.58vw;

    margin-top: 27px;
    margin-bottom: 27px;
  }

  ul,
  li {
    margin-left: 0.69vw;

    font-size: 0.97vw;
    line-height: 1.45vw;
  }

  /* --------------------------Wealth--calculator--------------css---------/ */

  .wealth-calculator input {
    padding-left: 0.54vw;
    width: 8.33vw;

    font-size: 1.11vw;
    line-height: 1.66vw;

    padding-bottom: 0.27vw;
    border-bottom: 0.06vw solid #d0d0d0;
  }

  .wealth-calculator span {
    font-size: 1.11vw;
  }

  .wealth-subheading {
    font-size: 0.97vw;
    line-height: 1.18vw;
  }

  .ant-slider-rail {
    width: 35.7vw;
    height: 0.55vw;
  }

  .ant-slider-handle {
    margin-top: -0.55vw;
    width: 1.66vw;
    height: 1.66vw;
  }

  .ant-slider-dot {
    margin-top: -0.53vw;
    width: 1.66vw;
    height: 1.66vw;
  }

  .ant-slider-track {
    height: 0.55vw;
  }

  .ant-slider:hover .ant-slider-track {
    height: 0.55vw;
  }

  /* .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  background: #683fdb;
  border: #683fdb;
} */
  .ant-slider-dot-active {
    margin-top: -0.45vw !important;
    width: 1.66vw !important;
    height: 1.66vw !important;
  }

  .ant-slider-mark-text {
    margin-top: 3.82vh;

    font-size: 0.97vw;
    line-height: 1.18vw;
  }

  .total-invsmt-text {
    font-size: 1.18vw;
    line-height: 1.66vw;
  }

  .rupee {
    font-size: 1.18vw;
    line-height: 1.66vw;
  }
}

@media only screen and (max-width: 767px) {
  .downloadtxt-png-cont {
    display: none;
  }
  .pageNumbers {
    display: none;
  }
  .pageNumbers li {
    margin: 8px;
  }
  .btn_prev_next {
    margin: 6px;
  }
  .client-details-main-container {
    padding-left: 5.33vw;
    margin-top: 8.71vw;
  }
  .clients-text {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
  }

  /* -----------about---upcoming-------------------------------- */
  .client-details-and-upcoming-container {
    flex-wrap: wrap;
    justify-content: unset;
  }
  .client-details-container {
    width: 87.93vw;
    height: 33.7vw;
    margin-top: 4.1vw;
  }
  .details-heading-info-container {
    gap: 6.15vw;
  }
  .client-detail-heading {
    font-size: 18px;
    line-height: 18px;
    margin-top: 8.71vw;
  }
  .clientInfoHeading {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 1.28vw;
    white-space: unset;
  }
  .clientInfoHeadingData {
    font-family: "Rubik";
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    white-space: unset;
  }
  .upcomming-transactions-container {
    width: 89.69vw;
  }
  .all-transaction-btn-text {
    margin: unset;
  }
  .icon-event-data-container {
    gap: 3.58vw;
  }
  .event-icon > svg {
    width: 22px;
    height: 24px;
  }
  .upcomming-transactions {
    margin-top: 4.1vw;
    /* overflow: unset; */
    width: 87.69vw;
    height: 35.76vw;
  }
  .upcomming-transactions-container .first-para {
    font-size: 14px;
    line-height: 21px;
    width: 75.64vw;
    /* height: 10.76vw; */
  }
  .upcomming-transactions-container p > span {
    font-size: 14px;
    line-height: 21px;
  }
/* -------------Add ---- event -------------------------------- */
.add-event-modal{
  border-radius: 4.10vw;
  max-width:88vw
}
.add-event-modal .ant-modal-content{
  width: 87.69vw !important;
  padding: 8.20vw 0  0  5.12vw;
  border-radius: 4.10vw !important;

}

.add-event-modal .ant-modal-header{
  padding: unset;
}
.add-event-modal .ant-modal-body{
  padding: unset;
  margin-top: 5vw;
}
.add-event-main-container{
  gap: 5.12vw;
}
.event-selecter{
  width: 77.69vw;
  font-size: 16px;
line-height: 18px;
}
.date-input-cont .add-event-input{
  width: 77.69vw;
}
.name-input-cont .add-event-input{
  width: 77.69vw;
}
.ant-picker-input > input::placeholder {
  font-size: 16px;
  line-height: 18px;
}
.date-input-cont .add-event-input input{
   height: 7vw;
   font-size:4vw
}
.add-event-modal .ant-modal-footer{
  margin: unset;
  padding: 8.20vw 0 0 0;
}
.add-event-modal .ant-modal-footer .save-btn{
  width: 43.48vw;
  height: 12.30vw;

}
.add-event-modal .ant-modal-footer .save-btn span{
  font-size: 16px;
  line-height: 20px;
}
.add-event-modal .ant-modal-footer .cancel-btn{
  box-shadow:unset
}
.add-event-modal .ant-modal-footer .cancel-btn span{
  font-size: 16px;
  line-height: 15px;
} 
.add-event-modal .ant-modal-close-x svg{
  font-size: 24px;
  line-height: 32px;
}
  /* ---------portfolio-summryy------------------------ */
  .portfolio-summry-container {
    /* display: none; */

    margin-top: 12.3vw;
  }
  .portfolio-diversi-heading {
    font-size: 18px;
    line-height: 18px;
  }
  .portfolio-sumry-data-container {
    width: 90.53vw;
    height: 60vw;
    margin-top: 4.1vw;
    row-gap: 6.15vw;
    flex-direction: unset;
  }
  .portfolio-sumry-data-container > div {
    width: 50%;
  }
  /* -------------investment----portfolio------------------------ */
  .investment-portFo-empty-Scrn {
  }

  .investment-portFo-empty-Scrn > svg {
    width: 31vw;
    height: 25vw;
  }
  .investment-portFo-empty-Scrn > h1 {
    margin-top: 1vw;
    font-size: 16px;
    line-height: 17px;
  }
  .investment-portFo-empty-Scrn > p {
    font-size: 12px;
    line-height: 14px;
    width: 65vw;
    padding-bottom: 2vw;
  }
  .investment-portf-cont {
    /* display: none; */
    margin-top: 12.3vw;
  }
  .investment-portfo-heading > h1 {
    font-size: 18px;
    line-height: 18px;
  }
  .invested-portFolio-table-cont {
    width: 90;
    height: auto;
    overflow:hidden;
    border-radius: 16px;
  }
  .invested-portFolio-table-cont .table-style-one{
    overflow: auto;
  }
/* -------for modal-------------------------------- */
.investment-portfolio-modal-cont{
  height: 114.86vw;
  top: 30.944vw;
  border-radius: 4.10vw !important;
}
.investment-portfolio-modal-cont .ant-modal-header{
  border-radius: 4.10vw !important;
}
.investment-portfolio-modal-cont .ant-modal-title{
  padding: 16.92vw 0 0  6.41vw;
     font-size: 24px;
     height: 28px;
}
.investment-portfolio-modal-cont .ant-modal-content{
  border-radius: 4.10vw !important;
} 
.investment-portfolio-modal-cont .ant-modal-body{
  padding: 8.20vw 0 0  0;
}
.investment-portfolio-modal-cont .invested-portFolio-table-cont{
  height: auto;
  border-radius:4.10vw
}
  /* ------------graph----------------------------- */
  .client-graph-container {
    /* display: none; */
  }
  .chart {
    width: 90vw;
    height: 50%;
    margin-top: 12.3vw;
  }
  .chart > canvas {
    width: 87vw !important;
    height: 53.84vw !important;
  }
  /* --------------transaction---------table------client------- */
  .transaction-container {
    margin-top: 12.3vw;
  }
  .transaction-text {
    font-size: 18px;
    line-height: 18px;
  }
  .transaction-table {
    width: 90vw;
    overflow:hidden;
    margin-top: 4.1vw;
  }
  .client-detl-pg-trnstn-cont .table-style-one{
    overflow: auto;
  }

  .client-detl-pg-trnstn-cont .table-style-one::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  
  }
  
  .client-detl-pg-trnstn-cont .table-style-one::-webkit-scrollbar-track-piece {
    background: #DADADA;
    opacity: 0.4;
    border-radius: 2.08vw;
  }
  
  ::-webkit-scrollbar-thumb {
    /* height: 3px; */
    background: #683FDB;
    border-radius: 2.08vw;
  }

  .client-detail-transaction-empty-scrn > svg {
    width: 31vw;
    height: 25vw;
  }
  .client-detail-transaction-empty-scrn > h1 {
    font-size: 22px;
    line-height: 24px;
  }
  .client-detail-transaction-empty-scrn > p {
    font-size: 12px;
    line-height: 18px;
    width: 77vw;
  }
  /* ---------------------recommendation-------fund------------ */
  .recommendation-container {
    /* display: none; */
    margin-top: 12.3vw;
  }
  .recommendation-heading {
    font-size: 18px;
    line-height: 18px;
    margin: unset;
  }
  .recmnd-data-container {
    margin-top: 4.61vw;
    display: block;
  }
  .rcmdfund-single-data-outer-container {
    /* width: 87vw; */
    width: 89.53vw;
  }
  .rcmndfund-single-data-inner-container {
    justify-content: space-between;
    /* gap: 13.84vw; */
  }
  .logo-fundName-container {
    width: 69vw;
    gap: 4.1vw;
  }
  .rcmndfund-logo-cont {
    width: 7.69vw;
    height: 7.69vw;
  }
  .remnd-heading {
    font-size: 14px;
    line-height: 18px;
  }
  .remnd-subheading {
    font-size: 10px;
    line-height: 18px;
  }
  .percentage-text {
    font-size: 14px;
    line-height: 18px;
  }
  .negative-percentage-text {
    font-size: 14px;
    line-height: 18px;
  }
  .rcmndfund-sharelogo-precnt-container {
    gap: 4.35vw;
  }
  .recmndfund-share-icon > svg {
    width: 16px;
    height: 13px;
  }
  .horizntl-line {
    width: 89.53vw;
    opacity: 0.1;
  }

  /* ---------------wealth-calculator------------------------ */
  .cal-togle-btn-cont{
    margin-top: 8.20vw;
    width: 87.69vw;
    justify-content: space-between;
  }
  .cal-togle-btn-cont > .toggle-btn{
    width: 40vw;
    margin: unset;
    font-size: 20px;
    line-height: 30px;
  }
  .cal-togle-btn-cont > .toggle-btn-actv{
    width: 40vw;
    height: 10.11vw;
    margin: unset;
    font-size: 20px;
    line-height: 30px;
    border-bottom: 0.79vw solid #683FDB;
  }
  .wealth-calculator-container {
    /* display: none; */
  }
  .wealth-calculator-container .ant-slider-mark-text{
         margin-top: 3.84vw;
  }
  .tenative-period-heading-contianer {
    margin-top: 6.15vw;

  }
  .wealth-calculator span {
    font-size: 14px;
    line-height: 17px;
  }
  .slider-container {
    margin-top: 4.1vw;
  }
  .hdng_intrate_inpt_container{
    margin-top: 18.20vw;
    width: 87vw;
  }
  .hdng_intrate_inpt_container>p{
    font-size: 14px;
    line-height: 17px;
  }
  .hdng_intrate_inpt_container >.prctg-icn{
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #D0D0D0
  }
  .hdng_intrate_inpt_container input{
    width: 10vw;
    max-width: unset;
  }
  .rtn-slider-container{
    width: 87.53vw;
    margin-top: 6.41vw;
  }
  .rtn-slider-container .ant-slider-handle{
    border: 0.51vw solid white;
  }
  .investment-return-value-container {
    margin-top: 12.3vw;
  }
  .total-invsmt-text {
    font-size: 12px;
    line-height: 24px;
  }
  .wealth-calculator .ant-slider-rail {
    width: 87.53vw;
  }
  .wealth-calculator .ant-slider-step {
    /* width: 200%; */
  }
  .wealth-calculator .ant-slider-mark {
    /* width: 200%; */
  }
}
