.funding-list-container {
  padding: calc(0.55vw + 2.96vw);
}
.filter-text-icon-cont {
  display: flex;
  justify-content: space-between;
  margin-top: 1.22vw;
  width: 73.33vw;
  /* margin-left: 3.33vw; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
}
.filter-text-icon-cont > svg {
  display: none;
}
.filter-heading {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  margin: O;
}
.chechbox-filter-container {
  display: flex;
  gap: 2.24vw 1.66vw;
  flex-wrap: wrap;
  width: 73.33vw;
}

.all-fund-text {
  /* margin-top: 3.33vw; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  margin-bottom: 0;
}
.csvfile-download-container{
  display: flex;
  justify-content: space-between;
  margin-top: 3.33vw;
  width: 73.33vw;
}
.download-txt-png-cont {
  display: flex;
  gap: 0.7vw;
}

.download-txt-png-cont svg {
  width: 1.24vw !important;
  height: 1.53vw !important;
}

.download-txt-png-cont h6 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.041vw;
  margin-top: 0.4vw;
  /* color: #2775EB; */
  /* cursor: pointer; */
}

.downloadtxt-png-cont > img {
  align-self: flex-start;
}

.fundlist-table-container {
  margin-top: 1.11vw;
  width: 73.33vw;
  /* min-width: 900px; */
  background: #ffffff;
  box-shadow: 0px 0.27vw 1.25vw rgba(116, 82, 214, 0.15);
  border-radius: 1.11vw;
  /* border-bottom: none; */
  /* overflow: hidden; */
}
.amc-logo {
  width: 2.27vw;
  height: 2.27vw;
}
.shorting-arrow-container{
  display: flex;
  gap: 0.45vw;
  cursor: pointer;
}
.share-bookmark-container {
  display: flex;
  gap: 1.5vw;
  padding-right: 0.8vw;
  justify-content: space-evenly;
  cursor: pointer;
}

.fund-share-modal {
  width: 48.75vw !important;
  height: 34.65vw !important;
  /* left: 0px; */
  /* top: 0px; */

  background: #ffffff;
  border-radius: 1.11vw;
  z-index: 11;
  padding: 0;
}
.fund-share-modal .ant-modal-close {
  top: 0.27vw;
  right: 0.27vw;
}
.fund-share-modal .ant-modal-close-icon {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.666vw;
  line-height: 2.22vw;
  /* identical to box height, or 133% */

  color: #666666;
}

.fund-share-modal .ant-modal-content {
  border-radius: 1.11vw;
  padding-left: 4.375vw;
  height: 100%;
}

.fund-share-modal .ant-modal-title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;

  color: #333333;
}

.fund-share-modal .ant-modal-header {
  padding: 0;
  padding-top: 3.47vw;
  margin-bottom: 1.45vw;
  /* padding-left: 63px; */
  border-bottom: none;
  border-radius: 1.11vw;
}

.fund-share-modal .ant-modal-body {
  /* padding: 0;
  margin: 0; */
  padding: 0;
}

.fund-share-top {
  display: flex;
  gap: 3.33vw;
}

.fund-share-top .share-social {
  /* margin-left: 3.33vw; */
  /* width: 22.39vw; */
}

.share-social p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.97vw;
  line-height: 1.18vw;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 1.38vw;
  margin-top: 0.486vw;
}
.share-social img {
  width: 5.388vw;
  cursor: pointer;
}
.share-social .icons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.444vw;
  margin-top: 1.11vw;
}

.share-modal-checkbox-filter {
  display: flex;
  flex-direction: column;
  row-gap: 2.22vw;
  width: 32.416vw;
  height: 15.277vw;
  /* border-right: 1px solid #000000 0.2; */
  border-right: 0.0694vw solid rgba(0, 0, 0, 0.2);
  padding-top: 0.76vw;
}
.share-modal-checkbox-filter .selecttag-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.25vw;

  color: #000000;

  opacity: 0.8;
}

.share-modal-checkbox-filter .expand-cont {
  /* position: absolute; */
  /* width: 63.06vw !important; */
  z-index: 1;
}

.fund-share-modal .ant-modal-footer {
  display: none !important;
}

.funding-list-container .table-style-one td,
.funding-list-container .table-style-one th {
  padding-left: 1vw;
}

.share-send-btn {
  width: 11.87vw;
  height: 3.33vw;
  /* left: 63px;
  top: 226px; */

  /* Secondry color/Secondry Color */

  background: #ff9900 !important;
  border-radius: 0.54vw;
  border: none;
  
  color: #ffffff;
  /* cursor: pointer; */
}
.share-send-btn >span{
  color: #ffffff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.38vw;
  background: #ff9900;
}
/* .ant-btn::hover{
  background: #ff9900;
} */
.share-send-btn:hover{
  background: #ff9900;
}
.copy-url-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.97vw;
  line-height: 1.18vw;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 0.69vw;
}
.input-copyIcon-cont{
  display: flex;
  /* align-items: baseline; */
  gap: .4vw;
}
.copy-url-container input {
  width: 39.58vw;
  height: 3.75vw;

  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 0.55vw;

  padding-bottom: 0;
}
.copy-url-container input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 1.11vw;
  line-height: 2.5vw;
  /* identical to box height, or 225% */

  color: #666666;

  opacity: 0.5;
}
.copy-url-container img {
  height: 2.5vw;
  width: 2.5vw;
  margin-left: 0.694vw;
  cursor: pointer;
}
.copy-icon-cont{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4vw;
  height: 4vw;
  /* align-self: center; */
}
/* .copy-icon > svg{
  width: 1vw;
  height: 1vw;
} */
.copy-icon-cont:hover{
  
  background-color :#cccccc;
  border-radius: 50%;
}

.after-click-copy-icon{
  width: 1.3vw;
  height: 1.3vw;
}
@media only screen and (max-width: 767px) {
  .funding-list-container {
    margin-top: 8.71vw;
    padding: unset;
    margin-left: 5.15vw;
  }
  .filter-text-icon-cont {
    margin-top: 6.41vw;
    display: flex;
    justify-content: unset;
    gap: 3vw;
  }
  .filter-text-icon-cont > svg {
    display: flex;
  }
  .chechbox-filter-container {
    display: none;
  }
  .all-fund-text {
    font-size: 18px;
    line-height: 18px;
  }
  .csvfile-download-container{
    width: 89vw;
    align-items: baseline;
  }
  .download-txt-png-cont svg {
    width: 7.69vw !important;
    height: 7.69vw !important;
  }
  .download-txt-png-cont h6 {
    display: none;
  }
  .fundlist-table-container {
    width: 90vw;
    height: auto;
    margin-top: 4.1vw;
    border-radius: 16px;
    overflow: hidden;
  }
  .fundlist-table-container .table-style-one{
    overflow: auto;
  }

  .fundlist-table-container .table-style-one::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  
  }
  
  .fundlist-table-container .table-style-one::-webkit-scrollbar-track-piece {
    background: #DADADA;
    opacity: 0.4;
    border-radius: 2.08vw;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #683FDB;
    border-radius: 2.08vw;
  }




  .funding-list-container .table-style-one td,
.funding-list-container .table-style-one th {
  padding-left: 20px;
}
.shorting-arrow-container{

}
.shorting-arrow-container > svg{
  width: 4.58vw;
  height: 3.05vw;
}
  .amc-logo {
    width: 35px;
    height: 35px;
  }
  .share-bookmark-container{
    gap: 3.5vw;
  }
  .share-bookmark-container > svg {
    width: 5.08vw;
    height: 5.26vw;

  }
/* ------------fund-share--modal-------------------------------- */
.fund-share-modal{
  width: 87.94vw !important;
  height: auto !important;
  top: 20px;
  border-radius: 4.10vw;

}
.fund-share-modal .ant-modal-title{
  font-size: 24px;
line-height: 28px;


}
.fund-share-modal .ant-modal-content{
  padding: 8.20vw 0 8.20vw 5.12vw;
  border-radius: 4.10vw;
  /* background-color: #000000; */

}
.fund-share-modal .ant-modal-header{
  padding: unset;
  margin: unset;
}
.fund-share-modal .ant-modal-close{

}
.fund-share-modal .ant-modal-close-x{

}
.fund-share-modal .ant-modal-close-icon{
  font-size: 20px;
  line-height: 25px;
}
.share-modal-checkbox-filter{
  border-right: unset;
  width: 63.06vw;
  row-gap: 8.20vw;
  height: auto;
}
.share-modal-checkbox-filter .checkbox-filter-cont>.click-div{
  width: 63.06vw;
}
.share-modal-checkbox-filter .expand-cont{
  width: 63.06vw !important;
}
.share-modal-checkbox-filter .selecttag-text{
  font-size: 16px;
  line-height: 18px;
}
.share-send-btn{
  width: 43.84vw;
  height: 12.30vw;
  border-radius: 2.05vw;

}
.share-send-btn >span{
  font-size: 16px;
  line-height: 20px;
}
.fund-share-top{
flex-direction: column;
gap: 12.30vw;
margin-top: 8vw;
}

.fund-share-top .share-social{

}
.share-social p{
  font-size: 14px;
  line-height: 17px;
  margin: unset;
}
.share-social .icons-container{
  gap: 5.12vw;
  margin-top: 5.12vw;

}
.share-social .icons-container  svg{
  width: 77.64px;
  height: 77.64px;
}
.copy-url-container{
   margin-top: 12.30vw;
}
.copy-url-text{
  font-size: 14px;
  line-height: 17px;
  margin: unset;
}
.input-copyIcon-cont{
  margin-top: 2.56vw;
  gap: 2.03vw;
}
.copy-url-container input{
  width: 68.20vw;
  height: 13.84vw;
  border-radius: 2.05vw;
}
.copy-icon-cont{
   width: unset;
    height: unset;
   }
.copy-icon-cont > svg{
  width: 36px;
  height: 36px;
}
}
