.event-main-container {
  padding: 1.5em;
  width: 23.33vw;
  height: 34.71vh;
  overflow-x: hidden;
}
.icon-eventdetails-cont {
  display: flex;
  gap: 1.2vw;
}
.name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #333333;
  opacity: 0.8;
  white-space: nowrap;
  text-align: initial;
  margin-bottom: 0%;
}
.date {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
  text-align: initial;
  color: #666666;
}
.send-wishes-btn {
  width: 84px;
  height: 28px;
  background: #ff9900;
  border-radius: 6px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  border-style: none;
}

.send-wishes-btn:hover {
  opacity: 0.8;
}
.event-main-container::-webkit-scrollbar {
  width: 0.27vw;
  /* outline: 1px solid slategrey; */
}

.event-main-container::-webkit-scrollbar-track-piece {
  background: #dadada;
  opacity: 0.4;
  border-radius: 2.08vw;
}

.event-main-container::-webkit-scrollbar-thumb {
  /* height: 3px; */
  background: #683fdb;
  border-radius: 2.08vw;
}

@media only screen and (min-width: 1024px) {
  .name {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 0.97vw;
    line-height: 1.18vw;
    letter-spacing: 0.1px;
    color: #333333;
    /* text-align: initial; */
    opacity: 0.8;
  }
  .date {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 300;
    font-size: 0.69vw;
    line-height: 1.7vh;
    /* text-align: initial; */
    color: #666666;
  }
  .send-wishes-btn {
    width: 5.83vw;
    height: 3.41vh;
    background: #ff9900;
    border-radius: 6px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 0.69vw;
    line-height: 1.46vh;
    color: #ffffff;
    border-style: none;
    cursor: pointer;
  }
}

.upcoming-event-empty-screen > h1 {
  margin-bottom: 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1.52vw;
  line-height: 1.66vw;
  color: #333333;
  opacity: 0.8;
  margin-top: 2.5vh;
}
@media only screen and (min-width: 1441px) {
  .name {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 0.97vw;
    line-height: 2.07vh;
    letter-spacing: 0.1px;
    color: #333333;
    opacity: 0.8;
  }
  .date {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 300;
    font-size: 0.69vw;
    line-height: 1.7vh;

    color: #666666;
  }
  .send-wishes-btn {
    width: 5.83vw;
    height: 3.41vh;
    background: #ff9900;
    border-radius: 6px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 0.69vw;
    line-height: 1.46vh;
    color: #ffffff;
    border-style: none;
  }
}

@media only screen and (max-width: 767px) {
  .event-main-container {
  }
  .icon-eventdetails-cont {
    gap: 3.58vw;
  }
  .event-icon-cont > svg {
    width: 5.64vw;
    height: 6.15vw;
  }
  .evnt-date-type-contianer > .date {
    font-size: 10px;
    line-height: 14px;
  }
  .upcoming-event-empty-screen > svg {
    width: 100%;
    /* height: auto; */
  }
  .upcoming-event-empty-screen > h1 {
    font-size: 18px;
    line-height: 18px;
    margin-top: 10vw;
  }
}
