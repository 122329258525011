.logout-popup {
  display: flex;
  justify-content: center;
  /* width: 47.70vw;
    height: 22.15vw; */
    top: 250px;
}
.logout-popup .ant-modal-content {
  width: 47.7vw;
  height: 22.15vw;
  background: #ffffff;
  border-radius: 1.11vw;
}
.logout-popup .ant-modal-body {
  padding: 6.22vw 10.97vw 6.52vw 10.97vw;
}
.logout-popup .ant-modal-footer {
  display: none;
}
.popup-content-cont > h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vw;
  line-height: 1.94vw;
  color: #333333;
  margin: unset;
  white-space: nowrap;
}
.popup-content-cont .btn-cont {
  display: flex;
  gap: 3.88vw;
  margin-top: 4.44vw;
  justify-content: center;
}
.btn-cont > .cancel-btn {
  width: 11.87vw;
  height: 3.33vw;
  background: #ff9900;
  border-radius: 0.55vw;
  border-style: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.38vw;
  color: #ffffff;
  cursor: pointer;
}
.btn-cont > .log-out-btn {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.04vw;
  color: #2775eb;
  border-style: none;
  background: none;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .logout-popup {
    top:33%;
    width: 87vw !important;
    height: 70vw !important;
  }
  .logout-popup .ant-modal-content {

    width: unset;
    height: unset;
    border-radius: 4.10vw;
  }
  .logout-popup .ant-modal-body{
    padding: 10.22vw 10.97vw 10.52vw 10.97vw;
  }
  .popup-content-cont > h1 {
    font-size: 18px;
    line-height: 18px;
    /* white-space: nowrap; */
  }
  .popup-content-cont .btn-cont{
    gap: 14.61vw;
    margin-top: 12.41vw;
  }
  .btn-cont > .cancel-btn {
    width: 33.48vw;
    height: 10.3vw;
    border-radius: 2.05vw;
    font-size: 16px;
    line-height: 20px;
  }
  .btn-cont > .log-out-btn {
    font-size: 16px;
    line-height: 15px;
  }
  .logout-popup .ant-modal-close-x svg{
    font-size: 24px;
    line-height: 32px;
  }
}
