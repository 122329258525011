.card-container{
    padding-right:3vw;
 display: flex;
width: 23.33vw;
height: 14.78vh;
background: #FFFFFF;
box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
border-radius: 16px;
justify-content:space-evenly;
align-items: center;
cursor: pointer;
}
.card-container:hover{
    box-shadow: 0px 8px 18px rgba(116, 82, 214, 0.25);
}
.card-container > svg {
    pointer-events: none;
}
.relation-ship-card-container{
    display: flex;
    width: 23.33vw;
    height: 14.78vh;
    background: #FFFFFF;
    box-shadow: 0px 4px 18px rgba(116, 82, 214, 0.15);
    border-radius: 16px;
    justify-content:center;
    align-items: center;
    /* cursor: pointer; */
}
.card-heading-value-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
align-items: center;
}
.active-clients-num{
    margin-top: 2.43vh;
 font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 1.8vw;
line-height: 4.75vh;
color: #683FDB;
margin-bottom: 5px;
pointer-events: none;
}
.heading-clients{
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 1.3vw;
line-height: 3.04vh;
color: #000000;
opacity: 0.5;
pointer-events: none;
}
@media only screen and (min-width: 1441px) {
    .card-container{
        /* width: 23.33vw; */
        /* height: 9.44vw; */
        border-radius: 1.11vw;
        }
}
@media only screen and (max-width: 767px){
    .card-container{
        /* flex-direction: column; */
        width: 87.69vw;
        height: 28.97vw;
        padding: unset;

        
    }
    .card-container > svg{
        /* display: none; */
        width: 82px;
        height: 82px;
    }
    .card-heading-value-cont{
        /* display: none; */
       
    }
    .active-clients-num{
        font-size: 28px;
        line-height: 40px;
    }
    .heading-clients{
        font-size: 18px;
line-height: 21px;
    }
    .relation-ship-card-container{
        width: 87.69vw;
        height: 28.97vw;
        margin-top: 4.10vw;
    }
}